import React, { useMemo } from 'react';
import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';

import './style.css';

export const Iframe = ({ src, srcDoc, width = '100%', height = '100%' }) => {
	const { lang } = useAppContext();

	const srcURLByLang = useMemo(() => {
		const _Language = Object.keys(Languages).map(lang => ({
			value: Languages[lang]?.Id,
			label: Languages[lang]?.Name,
			ISO: Languages[lang]?.ISO,
		}));
		const selectedLangISO = _Language.find(l => l.value === lang).ISO || 'EN';
		const url = new URL(src.trim(), window.location.origin);
		url.searchParams.set('utm_lang', selectedLangISO.toLowerCase());
		return url.toString();
	}, [src, lang]);

	return (
		<div className="Iframe">
			{src && <iframe key={src} title="iframe" width={width} height={height} src={srcURLByLang} srcDoc={srcDoc} loading="lazy" />}
		</div>
	);
};

export default Iframe;
