import { useState, useEffect, useMemo } from 'react';
import { useAppContext } from 'services/context';
import moment from 'moment';

import InfoBox from 'components/InfoBox';
import { Languages } from 'constants/index.js';
import { stringToDate } from 'services/utils';

import './style.css';

const MonthPickerDDL = ({
	question,
	isValid,
	showValidationError,
	SetClientAnswer,
	fromDate,
	setFromDate,
	saveFromDateInGlobalStateVariable,
}) => {
	const formatString = 'MM/YYYY';
	const value = moment(question.ClientAnswer.InputValue, formatString);
	const today = moment();
	const minParse = stringToDate(question.MinValue);
	const maxParse = stringToDate(question.MaxValue);
	const maximumDate = maxParse ? maxParse : today;
	const minimumDate = minParse ? minParse : today.clone().subtract(100, 'year');
	const { lang, IsLocked } = useAppContext();
	const [validDate, setValidDate] = useState(true);
	const [selectedDate, setSelectedDate] = useState({ year: value.year(), month: value.month() });
	const [currFromDate, setCurrFromDate] = useState({});

	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const Description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	//check if it's the 'from' select or 'to' select question
	const isToQuestion = Description.QuestionText.toLowerCase().includes('to');

	const years = useMemo(() => {
		const _minimumDate = isToQuestion && currFromDate.year ? currFromDate.year : minimumDate.year();

		return Array(maximumDate.year() - _minimumDate + 1)
			.fill()
			.map((_, i) => maximumDate.year() - i);
	}, [minimumDate, maximumDate]);

	const handleDateChange = stringDate => {
		try {
			setValidDate(true);
			const date = moment(stringDate, formatString);
			const maxDate = moment(maximumDate);
			const minDate = moment(minimumDate);
			if (stringDate.length === 0 || !date.isValid() || (maxDate && date.isAfter(maxDate)) || (minDate && date.isBefore(minDate))) {
				setValidDate(false);
				SetClientAnswer(question.QuestionId, null);
			} else if (date.isValid()) {
				SetClientAnswer(question.QuestionId, moment(date).format(formatString));
			}
		} catch (error) {
			setValidDate(false);
			console.error(error);
		}
	};

	const onDateChange = ({ year, month }) => {
		let fromQuestionData = {};
		if (month !== undefined) {
			setSelectedDate({ ...selectedDate, month: parseInt(month) });
			fromQuestionData = { questionId: question.QuestionId, month: parseInt(month), year: parseInt(currFromDate.year) };
		}
		if (year !== undefined) {
			setSelectedDate({ ...selectedDate, year: parseInt(year) });
			fromQuestionData = { questionId: question.QuestionId, year: parseInt(year), month: parseInt(currFromDate.month) };
		}
		if (!isToQuestion && fromQuestionData) {
			const _fromDate = saveFromDateInGlobalStateVariable(fromQuestionData);
			setFromDate([..._fromDate]);
		}
	};

	useEffect(() => {
		const date = moment(question.ClientAnswer.InputValue, formatString);
		if (!date.isValid()) {
			SetClientAnswer(question.QuestionId, null);
		}
	}, []);

	useEffect(() => {
		const userDate = moment([selectedDate.year, selectedDate.month]);
		handleDateChange(userDate.format(formatString));
		if (!isToQuestion) {
			let _fromDate = [...fromDate];
			const currQuestion = _fromDate?.find(currQuestion => currQuestion.questionId === question.QuestionId);
			if (!currQuestion) {
				_fromDate.push({ questionId: question.QuestionId, month: selectedDate.month, year: selectedDate.year });
				setFromDate([..._fromDate]);
			}
		}
	}, [selectedDate]);

	useEffect(() => {
		let _currFromDate;
		//The gap between the From and the To questions IDs is 2
		if (isToQuestion) _currFromDate = fromDate?.filter(currQuestion => currQuestion.questionId === question.QuestionId - 2)[0] || {};
		else _currFromDate = fromDate?.filter(currQuestion => currQuestion.questionId === question.QuestionId)[0] || {};
		setCurrFromDate(_currFromDate);

		//check the validation
		if (isToQuestion && _currFromDate.month && _currFromDate.year) {
			if (selectedDate.year !== '-1' && _currFromDate.year > selectedDate.year) setSelectedDate({ ...selectedDate, year: '-1' });
			if (selectedDate.month !== '-1' && _currFromDate.year === selectedDate.year && _currFromDate.month > selectedDate.month)
				setSelectedDate({ ...selectedDate, month: '-1' });
		}
	}, [fromDate, selectedDate]);

	const monthValidation = monthIndex => {
		return (
			(selectedDate.year == maximumDate.year() && monthIndex > maximumDate.month()) ||
			(isToQuestion && currFromDate.year == selectedDate.year && monthIndex < currFromDate.month)
		);
	};

	return (
		<div className="MonthPickerDDL">
			{Description.QuestionText && (
				<div className="MonthPickerDDL__title">
					<span dangerouslySetInnerHTML={{ __html: Description?.QuestionText || '' }}></span>
					{Description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}
					{Description.QuestionInformation.length !== 0 && <InfoBox message={Description.QuestionInformation} />}
					&nbsp;
				</div>
			)}
			<div className="date_picker">
				<select
					disabled={locked}
					defaultValue={selectedDate.month.toString() || '-1'}
					onChange={e => onDateChange({ month: e.target.value })}
				>
					<option value="-1">Month</option>
					{moment.months().map((month, monthIndex) => (
						<option key={month} value={monthIndex} className="month_value" disabled={monthValidation(monthIndex)}>
							{month}
						</option>
					))}
				</select>
				<select disabled={locked} defaultValue={selectedDate.year || '-1'} onChange={e => onDateChange({ year: e.target.value })}>
					<option value="-1">Year</option>
					{years.map(year => (
						<option key={year} value={year} className="year_value" disabled={isToQuestion && year < currFromDate.year}>
							{year}
						</option>
					))}
				</select>
			</div>
			{showValidationError && !validDate && (
				<label className="validation_error" dangerouslySetInnerHTML={{ __html: Description?.QuestionNoteTextArray?.join('<br/>') }}></label>
			)}
		</div>
	);
};

export default MonthPickerDDL;
