import { useMemo } from 'react';
import { useAppContext } from 'services/context';
import InfoBox from 'components/InfoBox';
import { Languages } from 'constants/index.js';

const Textarea = ({ question, questionType, isValid, showValidationError, SetClientAnswer }) => {
	const { lang, IsLocked } = useAppContext();

	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(desc => desc.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(desc => desc.Language.Id === Languages.English.Id);
	}, [question, lang]);

	const onType = e => SetClientAnswer(question.QuestionId, e.target.value);

	return (
		<div style={{ gridRow: question.RowNumber }}>
			<div className="textArea_title">
				{/* There is a title with <b> tag in the DB, that's why the title must be rendered like this */}
				<span dangerouslySetInnerHTML={{ __html: description?.QuestionText || '' }} />
				{description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}
				{description.QuestionInformation.length !== 0 && (
					<InfoBox message={description.QuestionInformation} questionId={question.QuestionId} />
				)}
				&nbsp;
			</div>
			<textarea
				type={questionType.htmlType}
				placeholder={description.PlaceHolder}
				onChange={onType}
				value={question.ClientAnswer.InputValue || ''}
				disabled={locked}
				rows="3"
				cols="50"
				style={{ resize: 'none' }}
			></textarea>

			{showValidationError && !isValid(question, questionType) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') || '' }}
				></label>
			)}
		</div>
	);
};

export default Textarea;
