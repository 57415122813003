export const WelcomeTabId = parseInt(process.env.REACT_APP_WELCOME_TAB);

const headers = new Headers();
headers.append('Authorization', '');
headers.append('Access-Control-Allow-Origin', '*');
headers.append('Content-Type', 'application/json');

const API_URL = window.Settings.API_URL || 'localhost';

export default class Api {
	constructor(setLoading) {
		if (setLoading && typeof setLoading === 'function') this.setLoading = setLoading;
		else this.setLoading = () => {};
	}

	async GetTabs() {
		try {
			const data = await this.FetchWithloader(`${API_URL}/Application/GetTabs`);
			return data;
		} catch (err) {
			console.warn(`GetTabs: ${err}`);
		}
	}

	async GetTab(tabId) {
		try {
			const data = await this.FetchWithloader(`${API_URL}/Application/GetTab?id=${tabId}`);
			return data;
		} catch (err) {
			console.warn(`GetTab #${tabId}: ${err}`);
		}
	}

	async SaveTab(Tab, IsSmartphone) {
		try {
			const urlParams = new URLSearchParams(window.location.search);
			let queryParams = {};
			if ([...urlParams.entries()].length > 1) {
				// Loop through all the params and add them to the queryParams object
				urlParams.forEach((value, key) => {
					queryParams[key] = value;
				});
			}
			const requestBody = {
				tab: Tab, // Tab object
				urlParams: queryParams, // Converted URL parameters as an object
				IsSmartphone,
			};
			const data = await this.FetchWithloader(`${API_URL}/Application/SaveTab`, {
				method: 'POST',
				headers: headers,
				body: JSON.stringify(requestBody),
			});

			if (data && data.Guid) {
				this.AttachAuth(data.Guid);
			}

			return data;
		} catch (err) {
			console.warn(`SaveTab ${err}`);
		}
	}

	async UploadFile(formData, fileTypeID = 1, tabId, questionId) {
		try {
			const data = await this.FetchWithloader(
				`${API_URL}/Application/Upload?fileTypeID=${fileTypeID}&tabId=${tabId}&questionId=${questionId}&gid=${window.gid}`,
				{
					method: 'POST',
					body: formData,
				}
			);
			return data;
		} catch (err) {
			console.warn(`UploadFile on tab #${tabId} and question #${questionId}: ${err}`);
		}
	}

	async GetFile(id, questionType) {
		try {
			const data = await this.FetchWithloader(
				`${API_URL}/Application/GetFile?id=${id}&questionType=${questionType}&gid=${window.gid}`,
				{ headers, method: 'GET' },
				this.HandleFileConvertion
			);
			return data;
		} catch (err) {
			console.warn(`GetFile #${id} type ${questionType}: ${err}`);
		}
	}

	AttachAuth(gid) {
		if (!window.gid) {
			const url = new URL(window.location.href);
			const gid = url.searchParams.get('gid');
			window.gid = gid;
		}
		if (gid) {
			window.gid = gid;
		}
		headers.set('Authorization', `Basic ${window.gid}`);
	}

	// file convertion middleware
	async HandleFileConvertion(res) {
		const _blob = await res.blob();
		if (!res.ok) return res.statusText;
		return {
			extension: res.headers.get('Content-Type'),
			filename: res.headers.get('content-disposition').split('filename=')[1],
			blob: _blob,
		};
	}

	// fetch middleware
	async HandleUnauthorized(res) {
		let data;
		try {
			if (res.ok) {
				data = await res.json();
				if (data.redirectUrl) {
					window.location.href = data.redirectUrl; // Perform the redirection
					return;
				}
				return data;
			}
			data = await res.text();
			return data || res.statusText;
		} catch (error) {
			console.error(error);
		}
	}

	async FetchWithloader(url, options = { headers, method: 'GET' }, callMiddleware = this.HandleUnauthorized) {
		//REMOVE COMMENTS BELLOW TO ADD LOADER ON DATA FETCH
		// let debounceTimeOut; //to use loader only if needed and not for few milliseconds
		this.AttachAuth();
		try {
			// debounceTimeOut = setTimeout(() => this.setLoading(true), 150);
			return await fetch(url, options).then(callMiddleware);
		} catch (error) {
			console.warn(error);
		} finally {
			// clearTimeout(debounceTimeOut);
			// this.setLoading(false);
		}
	}
}
