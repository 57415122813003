import { TABS_ACTIONS } from './actionTypes';

const TabsReducer = (tabs, action) => {
	switch (action.type) {
		case TABS_ACTIONS.SET_TABS:
			return [...action.Tabs];
		case TABS_ACTIONS.INSERT_TAB_TO_BEGIN:
			return tabs.shift(action.payload);
		case TABS_ACTIONS.INSERT_TAB_TO_END:
			return tabs.push(action.payload);

		default:
			return tabs;
	}
};

export default TabsReducer;
