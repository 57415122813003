import React, { useState, useEffect, useMemo } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MyPromise, { injectCSSVariables, loadPixels } from 'services/utils';

import SideTabs from 'containers/SideTabs';
import Header from 'containers/Header';
import Tab from 'components/Tab';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';
import { useSwipeable } from 'react-swipeable';
import ErrorPage from 'containers/ErrorPage';
import Iframe from 'components/Iframe';

import { SIDETABS_ACTIONS } from 'services/reducers/actionTypes';

import './App.css';

let navPromise;

const SWIPE_THRESHOLD = process.env.REACT_APP_SWIPE_THRESHOLD;

const App = () => {
	const { lang, currentTab, setSideTabs, sideTabs, isLoading, showErrorPage, appTitle, siteId, loginUrl } = useAppContext();

	const [isOpen, setModal] = useState(false);
	const [isNewAppModel, setNewAppModel] = useState(false);

	const history = createBrowserHistory();

	const getUserConfirmation = (message, callback) => {
		const newHistory = JSON.parse(message);
		if (newHistory.pathname === history.location.pathname) {
			callback(false);
			return;
		}
		setModal(true);

		navPromise = new MyPromise();
		navPromise.promise.then(data => {
			setModal(false);
			callback(data);
		});
	};

	//choose and load the relevent CSS variables by SITE_ID
	useEffect(() => {
		injectCSSVariables(siteId);
	}, [siteId]);

	const siteCssClass = useMemo(() => {
		return siteId ? `s${siteId}` : 's';
	}, [siteId]);

	const yesText = useMemo(() => {
		if (!currentTab) return '';
		const text = currentTab.GeneralTranslation?.Yes.find(obj => obj.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.Yes.find(obj => obj.LanguageId === Languages.English.Id).Value || '';
	}, [lang, currentTab]);

	const noText = useMemo(() => {
		if (!currentTab) return '';
		const text = currentTab.GeneralTranslation?.No.find(obj => obj.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.No.find(obj => obj.LanguageId === Languages.English.Id).Value || '';
	}, [lang, currentTab]);

	const popupMessage = useMemo(() => {
		if (!currentTab) return '';
		const text = currentTab.GeneralTranslation?.LeaveTab.find(msg => msg.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.LeaveTab.find(msg => msg.LanguageId === Languages.English.Id)?.Value || '';
	}, [lang, currentTab]);

	const migratedUserMessage = useMemo(() => {
		if (!currentTab) return '';
		const text = currentTab.GeneralTranslation?.AppMigrate_Popup_text?.find(msg => msg.LanguageId === lang);
		if (text) return text.Value?.replace('[sitename]', sideTabs.SiteName);
		return currentTab.GeneralTranslation?.AppMigrate_Popup_text?.find(msg => msg.LanguageId === Languages.English.Id).Value?.replace(
			'[sitename]',
			sideTabs.SiteName
		);
	}, [lang, currentTab]);

	const closeAndContinue = useMemo(() => {
		if (!currentTab) return '';
		const text = currentTab.GeneralTranslation?.AppMigrate_Popup_button?.find(msg => msg.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.AppMigrate_Popup_button?.find(msg => msg.LanguageId === Languages.English.Id).Value || '';
	}, [lang, currentTab]);

	const isRtl = useMemo(() => {
		const _isRtl = Object.values(Languages).find(obj => obj.Id === lang)?.isRtl;
		return _isRtl || false;
	}, [lang, Languages]);

	const langISO = useMemo(() => {
		return Object.values(Languages).find(obj => obj.Id === lang)?.ISO;
	}, [lang, Languages]);

	const closeModal = () => navPromise.resolve(false);
	const navigateTo = () => navPromise.resolve(true);

	const swipreHandler = useSwipeable({
		onSwipedLeft: e => {
			if (isRtl) {
				setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: e.deltaX < SWIPE_THRESHOLD });
			} else setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: e.deltaX > -SWIPE_THRESHOLD });
		},
		onSwipedRight: e => {
			if (isRtl) {
				setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: e.deltaX < SWIPE_THRESHOLD });
			} else setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: e.deltaX > -SWIPE_THRESHOLD });
		},
	});

	useEffect(() => {
		if (sideTabs?.UniqueDetails?.IsFirstLogin) {
			setNewAppModel(sideTabs.UniqueDetails.IsFirstLogin);
		}
	}, [sideTabs.UniqueDetails]);

	//SET TITLE
	useEffect(() => {
		if (appTitle) document.title = appTitle;
	}, [appTitle]);

	// SET FAVICON
	useEffect(() => {
		if (siteId) {
			//MAIN FAV
			const url = `/app/favicon${siteId}.png`;
			let mainFavicon = document.querySelector("link[rel~='icon']");
			if (!mainFavicon) {
				mainFavicon = document.createElement('link');
				mainFavicon.rel = 'icon';
				document.getElementsByTagName('head')[0].appendChild(mainFavicon);
			}
			mainFavicon.href = url;
			//APPLE TOUCH ICON - USED AS A WEB PAGE ICON ON THE IPHONE, IPOD AND IPAD - UTILIZED WHENEVER A USER BOOKMARKS THE APP
			let appleFavicon = document.querySelector("link[rel~='apple-touch-icon']");
			if (!appleFavicon) {
				appleFavicon = document.createElement('link');
				appleFavicon.rel = 'apple-touch-icon';
				document.getElementsByTagName('head')[0].appendChild(appleFavicon);
			}
			appleFavicon.href = url;
		}
	}, [siteId]);

	useEffect(() => {
		loadPixels(); // Load only the necessary pixel scripts
	}, []);

	try {
		return (
			<BrowserRouter history={history} basename="/app" getUserConfirmation={getUserConfirmation}>
				<div lang={langISO} className={`App ${siteCssClass}`} {...swipreHandler}>
					<Header />
					<SideTabs />
					<div className="content">
						<Switch>
							<Route path="/tab/:id" component={Tab} />
							<Route path="/signup/" component={Tab} />
							<Route path="*" component={() => <Iframe src={loginUrl} />} />
						</Switch>

						<Modal isOpen={isOpen}>
							<>
								<div className="modal__title">{popupMessage}</div>
								<div className="modal_buttons">
									<div onClick={navigateTo} className="button primary  modal__ok">
										{yesText}
									</div>
									<div onClick={closeModal} className="button primary modal__close">
										{noText}
									</div>
								</div>
							</>
						</Modal>

						<Modal isOpen={isNewAppModel}>
							<div className="newlook_popup">
								<div className="close" onClick={() => setNewAppModel(false)}></div>
								<div className="newlook_text" dangerouslySetInnerHTML={{ __html: migratedUserMessage }}></div>
								<div onClick={() => setNewAppModel(false)} className="button primary">
									{closeAndContinue}
								</div>
							</div>
						</Modal>
					</div>
					<Spinner visible={isLoading} />
				</div>
			</BrowserRouter>
		);
	} catch (error) {
		return <ErrorPage lang={lang} header siteCssClass={siteCssClass} />;
	}
};

export default App;
