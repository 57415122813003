import { useState, useMemo } from 'react';
import Select from 'react-virtualized-select';
import InfoBox from 'components/InfoBox';
import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';
import { IS_MOBILE } from 'services/utils';
import './style.css';

const maxMultiSelectMobile = process.env.REACT_APP_MAX_MUTLI_SELECT_ITEMS_MOBILE;

const MultiDDL = ({ question, SetClientAnswer, showValidationError, isValid, questionType, removeDefaultOption = true }) => {
	const { lang, IsLocked } = useAppContext();
	const [showAnswers, setShowAnswers] = useState(true);

	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const options = useMemo(() => {
		const defaultAnswer = question?.Answers?.find(ans => ans.IsDefault);
		let _answers;
		if (removeDefaultOption)
			_answers = question.Answers.filter(ans => ans.InputValue != defaultAnswer.InputValue); //to get rid of *Select Country* option
		else _answers = question.Answers;
		return _answers?.map(answer => {
			const label = answer.AnswerDescriptions.find(description => description.Language.Id === lang)
				? answer.AnswerDescriptions.find(description => description.Language.Id === lang).InputText
				: answer.AnswerDescriptions.find(description => description.Language.Id === Languages.English.Id).InputText;
			return {
				value: answer.InputValue,
				label,
			};
		});
	}, [question, lang]);

	// const jsxOptions = useMemo(() => {
	// 	return options.map(opt => (
	// 		<option key={opt.value} value={opt.value}>
	// 			{opt.label}
	// 		</option>
	// 	));
	// }, [options]);

	const onUserSelect = selectedValues => {
		setShowAnswers(true);
		const answers = selectedValues.map(item => item.value);
		if (answers.find(item => item === 'placeholder')) return;
		SetClientAnswer(question.QuestionId, answers);
	};

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	return (
		<div className="MultiDDL">
			<div className="MultiDDL__title">
				{description.QuestionText}
				{question && question.IsRequired && <span className="required">*</span>}
				{description.QuestionInformation.length !== 0 && (
					<InfoBox message={description.QuestionInformation} questionId={question.QuestionId} />
				)}
				&nbsp;
			</div>
			<Select
				value={question.MultipleAnswerList?.map(val => options.find(answer => answer.value === val.InputValue)) || null}
				multi={true}
				options={options}
				onChange={onUserSelect}
				disabled={locked}
			/>

			{showValidationError && !isValid(question, questionType) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') || '' }}
				></label>
			)}
		</div>
	);
};

export default MultiDDL;
