import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'containers/App';
import Provider from 'services/context';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './react-select.css';
import 'react-virtualized-select/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider>
		<App />
	</Provider>
);
