import { useEffect, useMemo } from 'react';
import InfoBox from 'components/InfoBox';
import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';

import './style.css';

const DDL = ({ question, SetClientAnswer, questionType, showValidationError, isValid }) => {
	const { lang, IsLocked } = useAppContext();
	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const options = useMemo(() => {
		if (!question?.Answers) {
			console.log('no question answers ' + question.QuestionId);
			return [];
		}

		const _options = question?.Answers.sort((_, b) => (b.IsDefault && b.InputValue > 0 ? -1 : 0)).map(answer => {
			const label = answer.AnswerDescriptions.find(description => description.Language.Id === lang)
				? answer.AnswerDescriptions.find(description => description.Language.Id === lang).InputText
				: answer.AnswerDescriptions.find(description => description.Language.Id === Languages.English.Id).InputText;

			return {
				IsDefault: answer.IsDefault,
				value: answer.InputValue,
				label,
			};
		});

		const hasPlaceholder = _options.some(opt => parseInt(opt.value) === -1);

		if (!hasPlaceholder) {
			let placeholderByLang = questionType.placeholders.find(_placeholder => _placeholder.languageId === lang);
			if (!placeholderByLang)
				placeholderByLang = questionType.placeholders.find(_placeholder => _placeholder.languageId === Languages.English.Id);

			if (placeholderByLang)
				_options.unshift({
					IsDefault: true,
					value: placeholderByLang.inputValue,
					label: placeholderByLang.inputText,
				});
		}
		return _options;
	}, [question, question?.Answers, question?.ClientAnswer?.InputValue, lang]);

	const jsxOptions = useMemo(() => {
		return options?.map(opt => (
			<option disabled={opt.IsDefault} key={opt.value} value={opt.value}>
				{opt.label}
			</option>
		));
	}, [options]);

	// useEffect(() => {
	// 	const option = options?.find(answer => answer.value === question.ClientAnswer.InputValue);
	// 	const defaultOption = options?.find(answer => answer.IsDefault) || options[0];
	// 	if (!option) SetClientAnswer(question.QuestionId, defaultOption?.value || null);
	// }, []);

	const onUserSelect = e => SetClientAnswer(question.QuestionId, e.target.value);

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	const dDLContainerValue = useMemo(() => {
		try {
			const selectedAnswer = options.find(answer => answer.value === question.ClientAnswer.InputValue);
			if (selectedAnswer) return selectedAnswer.value;
			const defaultAnswer = options.find(answer => answer.IsDefault);
			if (defaultAnswer) return defaultAnswer.value;
			return undefined;
		} catch (error) {
			return undefined;
		}
	}, [question, options]);

	return (
		<div className="DDL">
			{description.QuestionText && (
				<div className="DDL__title">
					<span dangerouslySetInnerHTML={{ __html: description?.QuestionText || '' }}></span>
					{description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}
					&nbsp;
					{description.QuestionInformation.length !== 0 && (
						<InfoBox message={description.QuestionInformation} QuestionId={question.QuestionId} />
					)}
				</div>
			)}
			{description.QuestionText === '' && <div className="DDL__title">&nbsp;</div>}

			<div className="DDL__container">
				<select disabled={locked} onChange={onUserSelect} value={dDLContainerValue}>
					{jsxOptions}
				</select>
			</div>

			{showValidationError && !isValid(question, questionType) && (
				<label className="validation_error" dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') }}></label>
			)}
		</div>
	);
};

export default DDL;
