import LanguageSelect from 'components/LanguageSelect';
import { useMemo } from 'react';
import { useAppContext } from 'services/context';
import { NavLink, withRouter } from 'react-router-dom';
import { Languages } from 'constants/index.js';
import { IS_MOBILE } from 'services/utils';
import { SIDETABS_ACTIONS } from 'services/reducers/actionTypes';

import './style.css';

import classNames from 'classnames';

import './style.css';

const Header = ({ history }) => {
	const { lang, sideTabs, currentTab, isMiniApp, setSideTabs, isServiceAgreementModalOpen } = useAppContext();

	const visibleTabs = useMemo(() => {
		if (!sideTabs?.TabLayoutList) return null;
		return sideTabs?.TabLayoutList.filter(tab => tab.IsVisible);
	}, [sideTabs]);

	const headerTabsClass = classNames('Header', {
		large_size: visibleTabs?.length > 5 && !IS_MOBILE,
	});

	const description = tab => {
		const text = tab.TabDescriptions?.find(_description => _description.Language.Id === lang) || '';
		if (text) return text;
		return tab.TabDescriptions?.find(_description => _description.Language.Id === Languages.English.Id);
	};

	const shouldNavigate = (e, tabId) => {
		if (history?.location?.pathname === `/tab/${tabId}` || history?.location?.pathname === `/signup/${tabId}`) e.preventDefault();
	};

	const navigateTo = (e, tabId) => {
		if (isServiceAgreementModalOpen) {
			e.preventDefault();
			return;
		}
		setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: false });
		shouldNavigate(e, tabId);
	};

	const toggleMenu = () => {
		setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: !sideTabs.IsMenuOpen });
	};

	const pathPrefix = useMemo(() => {
		if (isMiniApp) return 'signup';
		return 'tab';
	}, [isMiniApp]);

	const _renderNavLink = tab => {
		const navLinkClassName = classNames('nav_item', {
			'nav_item--welcome': tab.TabName === 'Welcome',
			'nav_item--completed': tab.IsComplete,
			'nav_item--disabled': isServiceAgreementModalOpen,
		});

		return (
			<NavLink
				onClick={e => navigateTo(e, tab.TabId)}
				key={tab.TabId}
				to={{
					pathname: `/${pathPrefix}/${tab.TabId}`,
					search: history?.location?.search || '',
				}}
				activeClassName="nav_item--selected"
				className={navLinkClassName}
			>
				{description(tab).DisplayName}
			</NavLink>
		);
	};

	return (
		<div className={headerTabsClass}>
			<div className={`burger ${sideTabs.IsMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
				<span className="line"></span>
				<span className="line"></span>
				<span className="line"></span>
			</div>
			<div className="logo"></div>
			<div className="Header__tab_links">{visibleTabs && visibleTabs.map(_renderNavLink)}</div>
			<LanguageSelect />
		</div>
	);
};

export default withRouter(Header);
