import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';
import { LANGAUGE_ACTIONS } from 'services/reducers/actionTypes';

import './style.css';

const languagesIdsToShow = [25, 34]; //use to filter unnecesery languages and still kep the app generic

const LanguageSelect = ({ history }) => {
	const { lang, setLang } = useAppContext();

	const LangChange = value => {
		value ||= 25;
		const url = new URL(window.location.href);
		url.searchParams.set('lng', value);
		history.replace({
			pathname: history?.location.pathname,
			search: url.search,
		});
		setLang({ type: LANGAUGE_ACTIONS.CHANGE_LANG, payload: value });
	};

	useEffect(() => {
		const url = new URL(window.location.href);
		const lng = url.searchParams.get('lng') || 25;
		LangChange(lng);
	}, []);

	const languagesOptions = useMemo(() => {
		return Object.keys(Languages)
			.map(lang => ({
				value: Languages[lang].Id,
				label: Languages[lang].Name,
				ISO: Languages[lang].ISO,
			}))
			.filter(lang => languagesIdsToShow.includes(lang.value)); //modify the array above to add more languages
	}, [Languages]);

	const _renderOption = opt => {
		return (
			<option key={opt.value} value={opt.value}>
				{opt.ISO}
			</option>
		);
	};

	return (
		<div className="LanguageSelect">
			<select onChange={e => LangChange(e.target.value)} value={lang}>
				{languagesOptions.map(_renderOption)}
			</select>
		</div>
	);
};

export default withRouter(LanguageSelect);
