import { useMemo } from 'react';
import InfoBox from 'components/InfoBox';

import classNames from 'classnames';

import { BOOL_VALUES, Languages } from 'constants/index.js';
import { useAppContext } from 'services/context';

import './style.css';

const Checkbox = ({ question, SetClientAnswer, isValid, showValidationError, questionType }) => {
	const { lang, IsLocked } = useAppContext();

	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const onChangeAnswer = () => {
		if (locked) return;
		const prevAnswer = question.ClientAnswer.InputValue || BOOL_VALUES.False;
		const newAnswer = prevAnswer === BOOL_VALUES.False ? BOOL_VALUES.True : BOOL_VALUES.False;
		SetClientAnswer(question.QuestionId, newAnswer);
	};

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	const checkboxClasses = classNames('Checkbox__element', {
		'Checkbox__element--selected': question.ClientAnswer.InputValue === BOOL_VALUES.True,
	});

	return (
		<div className="Checkbox">
			<div className={checkboxClasses} onClick={onChangeAnswer}></div>
			<div className="Checkbox__label">
				<span dangerouslySetInnerHTML={{ __html: description?.QuestionText || '' }}></span>
				{description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}
				{description.QuestionInformation.length !== 0 && (
					<InfoBox message={description.QuestionInformation} questionId={question.QuestionId} />
				)}
				&nbsp;
			</div>
			{showValidationError && !isValid(question, questionType) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') || '' }}
				></label>
			)}
		</div>
	);
};

export default Checkbox;
