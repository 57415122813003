import { useMemo } from 'react';
import { BOOL_VALUES, Languages } from 'constants/index.js';
import { useAppContext } from 'services/context';

const AddMore = ({ SetClientAnswer, question }) => {
	const { currentTab, IsLocked, lang } = useAppContext();
	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const onChangeAnswer = () => {
		if (!locked)
			SetClientAnswer(question.QuestionId, question.ClientAnswer.InputValue === BOOL_VALUES.True ? BOOL_VALUES.False : BOOL_VALUES.True);
	};

	const RemoveText = useMemo(() => {
		const text = currentTab.GeneralTranslation.Remove.find(description => description.LanguageID === lang);
		if (text) return text.Value;
		return currentTab.GeneralTranslation.Remove.find(description => description.LanguageID === Languages.English.Id).Value;
	}, []);

	const AddMoreText = useMemo(() => {
		const text = currentTab.GeneralTranslation.AddMore.find(description => description.LanguageID === lang);
		if (text) return text.Value;
		return currentTab.GeneralTranslation.AddMore.find(description => description.LanguageID === Languages.English.Id).Value;
	}, []);

	return (
		<div className="AddMore">
			<div className={`button ${!locked ? 'secondary' : 'disabled'}`} onClick={onChangeAnswer}>
				{question.ClientAnswer.InputValue === BOOL_VALUES.True ? RemoveText : AddMoreText}
			</div>
		</div>
	);
};

export default AddMore;
