import { useEffect, memo } from 'react';
import './style.css';
import classNames from 'classnames';

const Modal = memo(({ isOpen, setVisible, children, extraClassName, showHideButton }) => {
	useEffect(() => {
		if (isOpen) {
			window.scrollTo(0, 0);
			document.body.classList.add('stopScroll');
		} else document.body.classList.remove('stopScroll');
		return function cleanup() {
			document.body.classList.remove('stopScroll');
		};
	}, [isOpen]);

	const Hide = () => {
		if (setVisible) {
			setVisible(false);
		}
	};
	const className = classNames('Modal', {
		'Modal--open': isOpen,
		[extraClassName]: extraClassName,
	});

	if (!isOpen) return null;

	return (
		<div className={className} onClick={Hide}>
			<div className="Modal__content">
				{children}
				{showHideButton && (
					<div className="close" onClick={Hide}>
						X
					</div>
				)}
			</div>
		</div>
	);
});

export default Modal;
