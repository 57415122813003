import { useMemo } from 'react';
import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';
import './style.css';

const Info = ({ question }) => {
	const { lang } = useAppContext();

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	return <div className="Note" dangerouslySetInnerHTML={{ __html: description?.QuestionText || '' }}></div>;
};

export default Info;
