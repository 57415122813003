import { useMemo } from 'react';
import InfoBox from 'components/InfoBox';
import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';

import './style.css';

const RadioGroup = ({ question, SetClientAnswer, questionType, isValid, showValidationError }) => {
	const { lang, IsLocked } = useAppContext();
	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const onChangeAnswer = newAnswer => {
		if (!locked) SetClientAnswer(question.QuestionId, newAnswer);
	};

	const AnswerDesc = answer => {
		const text = answer.AnswerDescriptions.find(desc => desc.Language.Id === lang);
		if (text) return text.InputText;
		return answer.AnswerDescriptions.find(desc => desc.Language.Id === Languages.English.Id).InputText;
	};

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	return (
		<div className={`RadioGroup ${locked ? '_disabled' : ''}`}>
			<div className="RadioGroup__title">
				{description?.QuestionText || ''}
				{question && <span className="required">*</span>}
				{description.QuestionInformation.length !== 0 && (
					<InfoBox message={description.QuestionInformation} QuestionId={question.QuestionId} />
				)}
				&nbsp;
			</div>
			<div className="RadioGroup__inputs">
				{question.Answers.map(answer => (
					<div key={question.QuestionId + answer.InputValue} className="radio">
						<div
							onClick={() => onChangeAnswer(answer.InputValue)}
							className={`radio__element ${answer.InputValue === question.ClientAnswer.InputValue ? 'radio__element--selected' : ''}`}
						></div>
						<div className="radio__label">{AnswerDesc(answer)}</div>
					</div>
				))}
			</div>
			{showValidationError && !isValid(question, questionType) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') || '' }}
				></label>
			)}
		</div>
	);
};

export default RadioGroup;
