import React, { useState, useEffect, useMemo } from 'react';
import { useAppContext } from 'services/context';
import InfoBox from 'components/InfoBox';
import { Languages } from 'constants/index.js';
import { IS_MOBILE, stringToDate } from 'services/utils';
import moment from 'moment';
import './style.css';

const formatString = process.env.REACT_APP_FORMAT_STRING;

const DatePicker = ({ question, isValid, showValidationError, questionType, SetClientAnswer }) => {
	const { lang, IsLocked } = useAppContext();
	const [validDate, setValidDate] = useState(true);

	const today = moment();

	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);
	const minParse = stringToDate(question.MinValue);
	const maxParse = stringToDate(question.MaxValue);

	const maximumDate = maxParse ? maxParse : today;
	const minimumDate = minParse ? minParse : today.clone().subtract(100, 'year');

	const Description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	const handleDateChange = stringDate => {
		try {
			setValidDate(true);
			const date = moment(stringDate);
			const maxDate = moment(maximumDate);
			const minDate = moment(minimumDate);
			if (stringDate.length === 0 || !date.isValid() || (maxDate && date.isAfter(maxDate)) || (minDate && date.isBefore(minDate))) {
				setValidDate(false);
				SetClientAnswer(question.QuestionId, null);
			} else if (date.isValid()) {
				SetClientAnswer(question.QuestionId, moment(date).format(formatString));
			}
		} catch (error) {
			setValidDate(false);
			console.error(error);
		}
	};

	useEffect(() => {
		const date = moment(question.ClientAnswer.InputValue, formatString);
		if (!date.isValid()) {
			SetClientAnswer(question.QuestionId, null);
		}
	}, []);

	return (
		<div className="DatePicker">
			{Description.QuestionText && (
				<div className="DatePicker__title">
					<span dangerouslySetInnerHTML={{ __html: Description?.QuestionText || '' }}></span>
					{Description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}
					{Description.QuestionInformation.length !== 0 && <InfoBox message={Description.QuestionInformation} />}
					&nbsp;
				</div>
			)}
			{IS_MOBILE ? (
				<input
					value={moment(question.ClientAnswer.InputValue, formatString).format('YYYY-MM-DD')}
					disabled={locked}
					type="date"
					max={moment(maximumDate).format('YYYY-MM-DD')}
					min={moment(minimumDate).format('YYYY-MM-DD')}
					placeholder={formatString}
					onChange={e => handleDateChange(e.target.value)}
				></input>
			) : (
				<DesktopDatePicker
					disabled={locked}
					onDateChage={handleDateChange}
					value={moment(question.ClientAnswer.InputValue, formatString)}
					maximumDate={maximumDate}
					minimumDate={minimumDate}
				/>
			)}
			{showValidationError && !validDate && (
				<label className="validation_error" dangerouslySetInnerHTML={{ __html: Description?.QuestionNoteTextArray?.join('<br/>') }}></label>
			)}
		</div>
	);
};

const DesktopDatePicker = ({ maximumDate, minimumDate, value, onDateChage, disabled }) => {
	const GenerateDaysArray = numberOfDays =>
		Array(numberOfDays)
			.fill()
			.map((_, i) => i + 1);

	const [days, setDays] = useState(GenerateDaysArray(31));
	const [selectedDate, setSelectedDate] = useState({ year: value.year(), month: value.month(), day: value.date() });

	const onDateChange = ({ year, month, day }) => {
		if (year !== undefined) setSelectedDate({ ...selectedDate, year });
		if (month !== undefined) setSelectedDate({ ...selectedDate, month });
		if (day !== undefined) setSelectedDate({ ...selectedDate, day });
	};

	useEffect(() => {
		const lastDay = moment([selectedDate.year, selectedDate.month]).endOf('month');
		if (lastDay.isValid()) setDays(GenerateDaysArray(lastDay.date()));

		const userDate = moment([selectedDate.year, selectedDate.month, selectedDate.day]);
		onDateChage(userDate.format('YYYY-MM-DD'));
	}, [selectedDate]);

	const years = useMemo(() => {
		return Array(maximumDate.year() - minimumDate.year() + 1)
			.fill()
			.map((_, i) => maximumDate.year() - i);
	}, [minimumDate, maximumDate]);

	return (
		<div className="date_picker">
			<select disabled={disabled} defaultValue={selectedDate.month ?? '-1'} onChange={e => onDateChange({ month: e.target.value })}>
				<option value="-1">Month</option>
				{moment.months().map((month, monthIndex) => (
					<option key={month} value={monthIndex}>
						{month}
					</option>
				))}
			</select>
			<select disabled={disabled} defaultValue={selectedDate.day ?? '-1'} onChange={e => onDateChange({ day: e.target.value })}>
				<option value="-1">Day</option>
				{days.map(day => (
					<option key={day} value={day}>
						{day}
					</option>
				))}
			</select>
			<select disabled={disabled} defaultValue={selectedDate.year ?? '-1'} onChange={e => onDateChange({ year: e.target.value })}>
				<option value="-1">Year</option>
				{years.map(year => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</select>
		</div>
	);
};

export default DatePicker;
