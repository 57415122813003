import { useMemo } from 'react';
import { useAppContext } from 'services/context';
import { NavLink, withRouter } from 'react-router-dom';
import { Languages } from 'constants/index.js';
import { SIDETABS_ACTIONS } from 'services/reducers/actionTypes';

import './style.css';

import classNames from 'classnames';
import { Progressbar } from 'components/Progressbar';

const WelcomeTabId = parseInt(process.env.REACT_APP_WELCOME_TAB);
const SummaryTabId = parseInt(process.env.REACT_APP_SUMMARY_TAB);
const SideTabs = ({ history }) => {
	const { lang, sideTabs, currentTab, setSideTabs } = useAppContext();

	const visibleTabs = useMemo(() => {
		if (!sideTabs?.TabLayoutList) return null;
		return sideTabs?.TabLayoutList.filter(tab => tab.IsVisible);
	}, [sideTabs]);

	const hasSpouse = useMemo(() => {
		return sideTabs.UniqueDetails?.SpouseUnique && sideTabs.UniqueDetails?.SpouseUniqueLinkUrl;
	}, [sideTabs?.UniqueDetails]);

	const completePercentage = useMemo(() => {
		if (!visibleTabs) return 0;
		const tabsForCalc = visibleTabs.filter(({ TabId }) => TabId !== WelcomeTabId && TabId !== SummaryTabId);
		if (!tabsForCalc.length) return 0;
		const completed = tabsForCalc.reduce((acc, { IsComplete }) => acc + (IsComplete ? 1 : 0), 0);
		const totalLeft = tabsForCalc.length - completed;
		const percentageCompleted = ((tabsForCalc.length - totalLeft) / tabsForCalc.length) * 100;
		return percentageCompleted;
	}, [visibleTabs]);

	const description = tab => {
		const text = tab.TabDescriptions?.find(_description => _description.Language.Id === lang) || '';
		if (text) return text;
		return tab.TabDescriptions?.find(_description => _description.Language.Id === Languages.English.Id);
	};

	const shouldNavigate = (e, tabId) => {
		if (history?.location?.pathname === `/tab/${tabId}` || history?.location?.pathname === `/signup/${tabId}`) e.preventDefault();
	};

	const termsText = useMemo(() => {
		if (!currentTab) return undefined;
		const text = currentTab.GeneralTranslation?.Terms.find(desc => desc.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.Terms.find(desc => desc.LanguageId === Languages.English.Id).Value || '';
	}, [currentTab, lang]);

	const privacyText = useMemo(() => {
		if (!currentTab) return undefined;

		const text = currentTab.GeneralTranslation?.Privacy.find(desc => desc.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.Privacy.find(desc => desc.LanguageId === Languages.English.Id).Value || '';
	}, [currentTab, lang]);

	const aboutText = useMemo(() => {
		if (!currentTab) return undefined;

		const text = currentTab.GeneralTranslation?.About.find(desc => desc.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.About.find(desc => desc.LanguageId === Languages.English.Id).Value || '';
	}, [currentTab, lang]);

	const suppotText = useMemo(() => {
		if (!currentTab) return undefined;
		const text = currentTab.GeneralTranslation?.Support.find(desc => desc.LanguageId === lang) || '';
		if (text) return text.Value;
		return currentTab.GeneralTranslation?.Support.find(desc => desc.LanguageId === Languages.English.Id).Value || '';
	}, [currentTab, lang]);

	const navigateTo = (e, tabId) => {
		setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: false });
		shouldNavigate(e, tabId);
	};

	const logoutText = useMemo(() => {
		try {
			const language = Object.keys(Languages).find(_lang => Languages[_lang].Id === lang);
			if (language) return Languages[language].logoutText;
		} catch (ex) {
			return 'Logout';
		}
	}, [lang]);

	const _renderNavLink = tab => {
		const navLinkClassName = classNames('nav_item', {
			'nav_item--welcome': tab.TabName === 'Welcome',
			'nav_item--completed': tab.IsComplete,
		});

		return (
			<NavLink
				onClick={e => navigateTo(e, tab.TabId)}
				key={tab.TabId}
				to={{
					pathname: `/tab/${tab.TabId}`,
					search: history?.location?.search || '',
				}}
				activeClassName="nav_item--selected"
				className={navLinkClassName}
			>
				{description(tab).DisplayName}
			</NavLink>
		);
	};
	return (
		<div className={`SideTabs ${sideTabs.IsMenuOpen === true ? 'open' : 'close'}`}>
			{sideTabs && sideTabs.UniqueDetails && (
				<>
					<div className="user-details">
						<div className="name">
							{sideTabs.UniqueDetails.Fname} {sideTabs.UniqueDetails.Lname}
						</div>
						<div className="unique">
							<br />
							Application ID &nbsp;
							{hasSpouse && <br />}
							{hasSpouse && <>Me &nbsp;</>}
							{sideTabs.UniqueDetails.UniqueNum}
							{hasSpouse && (
								<span className="spouse_link">
									&nbsp;/&nbsp;Spouse ID&nbsp;
									<a href={sideTabs.UniqueDetails.SpouseUniqueLinkUrl}>{sideTabs.UniqueDetails.SpouseUnique}</a>
								</span>
							)}
						</div>
					</div>

					<br />

					<Progressbar progress={completePercentage} />
					<div className="divider" />

					<div className="main-content">
						<div className="main-content__tab_links">{visibleTabs && visibleTabs.map(_renderNavLink)}</div>
						<div className="contact">
							{sideTabs.TermsAndConditionsUrl && (
								<a href={sideTabs.TermsAndConditionsUrl} target="_blank" rel="noopener noreferrer">
									{termsText}
								</a>
							)}
							&nbsp;
							{sideTabs.PrivacyPolicyUrl && (
								<a href={sideTabs.PrivacyPolicyUrl} target="_blank" rel="noopener noreferrer">
									{privacyText}
								</a>
							)}
							&nbsp;
							{sideTabs.AboutUrl && (
								<a href={sideTabs.AboutUrl} target="_blank" rel="noopener noreferrer">
									{aboutText}
								</a>
							)}
							&nbsp;
							{sideTabs.HelpEmail && <a href={`mailto:${sideTabs.HelpEmail}`}>{suppotText}</a>}
						</div>

						<div className="divider logout_divider" />
						<div
							className="logout"
							onClick={() => {
								window.open('', '_self', '');
								window.close();
							}}
						>
							<div className="logout__image"></div>
							<div className="logout__text">{logoutText}</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

//{sideTabs.HelpEmail && <a href={`mailto:${sideTabs.HelpEmail}`}>Help</a>}
export default withRouter(SideTabs);
