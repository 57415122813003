import { useState, useRef, useEffect, useMemo } from 'react';
import InfoBox from 'components/InfoBox';
import { useAppContext } from 'services/context';

import { Languages, QUESTION_TYPES } from 'constants/index.js';
import { Base64 } from 'js-base64';
import './style.css';

const Upload = ({ question, questionType, showValidationError, isValid, SetClientAnswer }) => {
	const { lang, currentTab, IsLocked, Api } = useAppContext();

	const [file, setFile] = useState();
	const [loading, setLoading] = useState(false);
	const uploaderRef = useRef();
	const [uploadError, setUploadError] = useState('');

	const locked = useMemo(() => {
		if (questionType.id === QUESTION_TYPES.Upload.id) return !question.IsEditable;
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	const uploadText = useMemo(() => {
		const text = currentTab.GeneralTranslation.Upload.find(txt => txt.LanguageId === lang);
		if (text) return text.Value;
		return currentTab.GeneralTranslation.Upload.find(txt => txt.LanguageId === Languages.English.Id).Value;
	}, [currentTab, lang]);

	const GetFileById = FileId => {
		if (FileId) {
			setLoading(true);
			Api.GetFile(FileId, questionType.id)
				.then(file => {
					const url = window.URL.createObjectURL(file.blob);
					const fileDownload = file.filename.replace(/"/g, '');
					const filename = fileDownload.includes('=?utf-8?')
						? Base64.decode(fileDownload.substring(fileDownload.indexOf('B?') + 2, fileDownload.indexOf('?=')))
						: fileDownload;

					setFile({ href: url, download: filename });
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		const fileId = question.ClientAnswer.InputValue;
		GetFileById(fileId); // eslint-disable-next-line
	}, [question.ClientAnswer.InputValue]);

	const fileChanged = async e => {
		// add some loader
		setLoading(true);
		let formData = new FormData();
		formData.append('file', e.target.files[0]);
		try {
			setUploadError('');
			const res = await Api.UploadFile(formData, question.ApplicationFileTypeId || 1, currentTab.TabId, question.QuestionId);
			if (!Number.isInteger(res)) {
				setLoading(false);
				setUploadError(JSON.parse(res));
				return;
			}
			SetClientAnswer(question.QuestionId, res);
			GetFileById(res);
		} catch (error) {
			uploaderRef.current.value = ''; // show upload error
			setLoading(false);
			if (error instanceof Promise) {
				error.then(er => setUploadError(er.replace(/"/g, '')));
			}
		}
	};

	//handle when user click on delete file
	const _handleDeleteFile = async () => {
		SetClientAnswer(question.QuestionId, null);
		setFile(null);
	};

	return (
		<div className="Upload">
			<div className="Upload__title">
				<span dangerouslySetInnerHTML={{ __html: description?.QuestionText || '' }}></span>
				{description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}
				{description.QuestionInformation.length !== 0 && (
					<InfoBox message={description.QuestionInformation} QuestionId={question.QuestionId} />
				)}
				&nbsp;
			</div>
			<div className="Upload__input">
				{loading ? (
					<span className="loader secondary"></span>
				) : (
					<label htmlFor={`Upload__${question.QuestionId}`} className={`button ${!locked ? 'secondary' : 'disabled'}`}>
						{uploadText}
					</label>
				)}
				{file && (
					<>
						<span className="Upload__icon"></span>
						<a className="Upload__filename" href={file.href} download={file.download}>
							{file.download}
						</a>
						<div className="Upload__delete_file" onClick={_handleDeleteFile}></div>
					</>
				)}
			</div>
			<input
				disabled={locked}
				ref={uploaderRef}
				id={`Upload__${question.QuestionId}`}
				type="file"
				accept={question.FileTypeExtensionArray ? question.FileTypeExtensionArray.join(',') : '.*'}
				onChange={fileChanged}
			></input>
			{((showValidationError && !isValid(question, questionType)) || uploadError) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{
						__html: uploadError
							? uploadError
							: description?.QuestionNoteTextArray[description?.QuestionNoteTextArray.length - 1]?.join('<br/>') || '',
					}}
				></label>
			)}
		</div>
	);
};

export default Upload;
