import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';

import LangaugeReducer from 'services/reducers/languageReducer';
import SideTabsReducer from 'services/reducers/sideTabsReducer';
import CurrentTabReducer from 'services/reducers/currentTabReducer';
import TabsReducer from 'services/reducers/tabsReducer';

import { Languages, ROLES } from 'constants/index.js';
import { deepClone } from 'services/utils';

import ApiClass, { WelcomeTabId } from 'services/api';

import { CURRENT_TAB_ACTIONS, SIDETABS_ACTIONS, TABS_ACTIONS } from 'services/reducers/actionTypes';

export const AppContext = React.createContext();

const Provider = props => {
	const [currentTab, dispatchCurrentTab] = useReducer(CurrentTabReducer);
	const [sideTabs, dispatchSideTabs] = useReducer(SideTabsReducer, { IsMenuOpen: false });
	const [tabs, dispatchTabs] = useReducer(TabsReducer);
	const [lang, dispatchLanguage] = useReducer(LangaugeReducer, 25);
	const [IsLocked, setIsLocked] = useState(false);
	const [loginUserType, setLoginUserType] = useState(ROLES.User);
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorPage, setShowErrorPage] = useState(false);
	const [appTitle, setAppTitle] = useState(window.Settings.APP_TITLE || '');
	const [siteId, setSiteId] = useState(window.Settings.SITE_ID || '');
	const [loginUrl, setLoginUrl] = useState(window.Settings.LOGIN_URL || '');
	const [isServiceAgreementModalOpen, setIsServiceAgreementModalOpen] = useState(false);

	const isMiniApp = useMemo(() => {
		return window.location.pathname.includes('signup');
	}, [window.location.pathname]);

	const IsCSR = useMemo(() => {
		if (loginUserType <= 0) return false;
		return loginUserType === ROLES.CSR;
	}, [sideTabs]);

	const IsLawyer = useMemo(() => {
		if (loginUserType <= 0) return false;
		return loginUserType === ROLES.Lawyer;
	}, [sideTabs]);
	const Api = new ApiClass(setIsLoading);

	const SetTab = async _tabId => {
		if (!_tabId) return;
		try {
			let foundTab;
			if (tabs) {
				const _foundTab = tabs.find(tab => tab.TabId === _tabId);
				if (_foundTab) foundTab = deepClone(_foundTab); // create new object with new REFERENCE!!! noting else worked
			}
			if (!foundTab) {
				foundTab = await Api.GetTab(_tabId);
			}
			dispatchCurrentTab({ type: CURRENT_TAB_ACTIONS.SET_CURRENT_TAB, payload: foundTab });
			return foundTab;
		} catch (err) {
			return err;
		}
	};

	const _getApplicationTabs = async (_sideTabsList, _fullAppTabsList) => {
		try {
			const sideTabs = _sideTabsList || (await Api.GetTabs());
			if (!IsLocked && !IsCSR) setIsLocked(sideTabs?.IsAppLocked);
			let filteredTabLayoutList = sideTabs?.TabLayoutList?.filter(tab => tab.IsVisible);
			filteredTabLayoutList = filteredTabLayoutList?.sort((a, b) => a.OrderInApplication - b.OrderInApplication);
			if (sideTabs.LoginUserType > 0) {
				setLoginUserType(sideTabs.LoginUserType);
			}
			const _sideTabs = {
				...sideTabs,
				TabLayoutList: [...filteredTabLayoutList],
			};
			dispatchSideTabs({ type: SIDETABS_ACTIONS.SET_SIDETABS, payload: _sideTabs });

			if (_fullAppTabsList) {
				dispatchTabs({ type: TABS_ACTIONS.SET_TABS, Tabs: _fullAppTabsList });
				return;
			}
			await GetAllTabs(_sideTabs);
		} catch (err) {
			console.log('fetch side tabs error: ', err);
			setShowErrorPage(true);
		}
	};

	const GetAllTabs = async ({ TabLayoutList, Tabs }) => {
		try {
			if (Tabs) {
				dispatchTabs({ type: TABS_ACTIONS.SET_TABS, Tabs: Tabs });
				return;
			}
			const promises = [];
			TabLayoutList.forEach(async sideTab => {
				if (!sideTab.IsVisible) return;
				promises.push(Api.GetTab(sideTab.TabId));
			});
			const fetchedTabs = await Promise.all(promises);
			dispatchTabs({ type: TABS_ACTIONS.SET_TABS, Tabs: fetchedTabs });
		} catch (err) {
			console.log('fetch side tabs error: ', err);
			setShowErrorPage(true);
		}
	};

	useEffect(() => {
		_getApplicationTabs();
		if (!window.gid) {
			const url = new URL(window.location.href);
			const gid = url.searchParams.get('gid');
			setShowErrorPage(Boolean(!gid));
		}
	}, []);

	useEffect(() => {
		if (lang === Languages.Arabic.Id) {
			document.body.setAttribute('dir', 'rtl');
			return;
		}
		document.body.setAttribute('dir', 'ltr');
	}, [lang]);

	const SaveTab = async (Tab, IsSmartphone) => {
		try {
			const TabSections = Tab.TabSections.filter(section => section.IsActive); // filter all not active sections
			const tabList = await Api.SaveTab({ ...Tab, TabSections }, IsSmartphone); //save the current tab
			await _getApplicationTabs(tabList, tabList?.Tabs);

			let nextTabId = tabList?.NextTab?.TabId;
			if (!nextTabId) {
				const foundNotCompletedTab = tabList.TabLayoutList.filter(tab => tab.IsVisible).find(tab => tab.IsComplete === false);
				nextTabId = foundNotCompletedTab && foundNotCompletedTab.TabId !== Tab.TabId ? foundNotCompletedTab.TabId : WelcomeTabId; //find the next uncompleted tab
			}
			if (!IsLocked && !IsCSR && !IsLawyer) setIsLocked(tabList.IsAppLocked);

			return nextTabId; //resolve this with the next tab in line or welcome tab
		} catch (error) {
			console.log(error);
		}
	};

	const state = {
		currentTab,
		sideTabs,
		setSideTabs: dispatchSideTabs,
		SetTab,
		globalAppTabs: tabs,
		SaveTab,
		lang,
		setLang: dispatchLanguage,
		IsLocked,
		isLoading,
		setIsLoading,
		IsCSR,
		IsLawyer,
		isMiniApp,
		Api,
		showErrorPage,
		appTitle,
		siteId,
		loginUrl,
		isServiceAgreementModalOpen,
		setIsServiceAgreementModalOpen,
	};

	return <AppContext.Provider value={state}>{props.children}</AppContext.Provider>;
};

export default Provider;

export const useAppContext = () => {
	return useContext(AppContext);
};
