import logo4 from 'assets/Logo4.svg';
import logo10 from 'assets/Logo10.svg';
import logo15 from 'assets/Logo15.svg';
import logo18 from 'assets/Logo18.svg';
import logo32 from 'assets/Logo32.svg';
import logo25 from 'assets/Logo25.svg';
import logo33 from 'assets/Logo33.svg';
import logo34 from 'assets/Logo34.svg';
import logo38 from 'assets/Logo38.svg';
import logo39 from 'assets/Logo39.svg';
import logo40 from 'assets/Logo40.svg';
import logo56 from 'assets/Logo56.svg';
import logo57 from 'assets/Logo57.svg';
import sidemenu_bg56 from 'assets/sidemenu_bg56.svg';
import sidemenu_bg57 from 'assets/sidemenu_bg57.svg';

export const styles = [
	{
		id: 4,
		variables: `
        --header-color: #303030;
        --side-menu-bg-color: #636363;
        --side-menu-bg-mobile-color: #636363;
        --userprogress-bg-deep: #af001e;
        --userprogress-bg-light: #ff002c;
        --userprogress-color: #000;
        --section-title-color: #182637;
        --header-nav-color: #fff;
        --header-nav-bg-completed: #a7041f;
        --side-menu-color: #fff;
        --button-color: #fff;
        --button-bg-color: #a7041f;
        --button-hover-color: #fff;
        --button-hover-bg-color: #ed1c24;
        --button-focus-bg-color: #a7041f;
        --button-focus-color: #a0a0a0;
        --button-border-radius: 30px;
        --question-color: #a0a0a0;
        --application-logo: url(${logo4}) no-repeat left center;
        --application-logo-size: auto 54px;
        --application-logo-mobile-size: auto 43px;
        --disabled-color: #a0a0a0;
        --button-disabled-color: #a0a0a0;
        --header-border: 0px solid transperent;
		--login-bg-color:#ebebeb;
        `,
	},
	{
		id: 10,
		variables: `
	--header-color: #fff;
	--side-menu-bg-color: #e0e0e0;
	--side-menu-bg-mobile-color: #e0e0e0;
	--userprogress-bg-deep: #e53232;
	--userprogress-bg-light: #e53232;
	--userprogress-color: #000;
	--section-title-color: #c50606;
	--header-nav-color: #000;
	--header-nav-bg-completed: #ec1c24;
	--side-menu-color: #000;
	--button-color: #fff;
	--button-bg-color: #c50606;
	--button-hover-color: #fff;
	--button-hover-bg-color: #e53232;
	--button-focus-bg-color: #c50606;
	--button-focus-color: #fff;
	--button-border-radius: 30px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo10}) no-repeat left center;
	--application-logo-size: auto 50px;
	--application-logo-mobile-size: auto 42px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 1px solid #636363;
	--login-bg-color:#ededed;
	--mobile-link-color:#000;
        `,
	},
	{
		id: 15,
		variables: `
    --header-color: #374861;
	--side-menu-bg-color: #e0e0e0;
	--side-menu-bg-mobile-color: #e0e0e0;
	--userprogress-bg-deep: #303030;
	--userprogress-bg-light: #ec1e24;
	--userprogress-color: #000;
	--section-title-color: #374861;
	--header-nav-color: #fff;
	--header-nav-bg-completed: #182637;
	--side-menu-color: #000;
	--button-color: #fff;
	--button-bg-color: #ec1e24;
	--button-hover-color: #fff;
	--button-hover-bg-color: #182637;
	--button-focus-bg-color: #ec1e24;
	--button-focus-color: #fff;
	--button-border-radius: 0px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo15}) no-repeat left center;
	--application-logo-size: auto 47px;
	--application-logo-mobile-size: auto 40px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 0px solid transperent;
	--login-bg-color:#fff;
        `,
	},
	{
		id: 18,
		variables: `
	--header-color: #fff;
	--side-menu-bg-color: #636363;
	--side-menu-bg-mobile-color: #636363;
	--userprogress-bg-deep: #bf272d;
	--userprogress-bg-light: #db272c;
	--userprogress-color: #000;
	--section-title-color: #13506c;
	--header-nav-color: #13506c;
	--header-nav-bg-completed: #18516c;
	--side-menu-color: #fff;
	--button-color: #fff;
	--button-bg-color: #397022;
	--button-hover-color: #fff;
	--button-hover-bg-color: #4d982d;
	--button-focus-bg-color: #397022;
	--button-focus-color: #fff;
	--button-border-radius: 3px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo18}) no-repeat left center;
	--application-logo-size: auto 50px;
	--application-logo-mobile-size: auto 42px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 3px solid #636363;
	--login-bg-color:#fff;
        `,
	},
	{
		id: 25,
		variables: `
    --header-color: #2b4d7d;
	--side-menu-bg-color: #e0e0e0;
	--side-menu-bg-mobile-color: #e0e0e0;
	--userprogress-bg-deep: #2b4d7d;
	--userprogress-bg-light: #27aae0;
	--userprogress-color: #000;
	--section-title-color: #2b4d7d;
	--header-nav-color: #fff;
	--header-nav-bg-completed: #fff;
	--side-menu-color: #2b4d7d;
	--button-color: #fff;
	--button-bg-color: #e60a24;
	--button-hover-color: #fff;
	--button-hover-bg-color: #27aae0;
	--button-focus-bg-color: #e60a24;
	--button-focus-color: #fff;
	--button-border-radius: 3px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo25}) no-repeat left center;
	--application-logo-size: auto 50px;
	--application-logo-mobile-size: auto 50px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 0px solid transperent;
	--login-bg-color:#fff;
        `,
	},

	{
		id: 32,
		variables: `
    --header-color: #fff;
	--side-menu-bg-color: #e0e0e0;
	--side-menu-bg-mobile-color: #e0e0e0;
	--userprogress-bg-deep: #ef3d37;
	--userprogress-bg-light: #f15d26;
	--userprogress-color: #000;
	--section-title-color: #000;
	--header-nav-color: #000;
	--header-nav-bg-completed: #0c95d8;
	--side-menu-color: #2b4d7d;
	--button-color: #fff;
	--button-bg-color: #ef3e37;
	--button-hover-color: #fff;
	--button-hover-bg-color: #f1562a;
	--button-focus-bg-color: #ef3e37;
	--button-focus-color: #fff;
	--button-border-radius: 3px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo32}) no-repeat left center;
	--application-logo-size: auto 50px;
	--application-logo-mobile-size: auto 40px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 1px solid #333;
	--login-bg-color:#fff;
        `,
	},
	{
		id: 33,
		variables: `
    --header-color: #e0e0e0;
	--side-menu-bg-color: #264458;
	--side-menu-bg-mobile-color: #264458;
	--userprogress-bg-deep: #9a1d1f;
	--userprogress-bg-light: #d7484c;
	--userprogress-color: #000;
	--section-title-color: #000;
	--header-nav-color: #000;
	--header-nav-bg-completed: #323743;
	--side-menu-color: #fff;
	--button-color: #fff;
	--button-bg-color: #b73235;
	--button-hover-color: #fff;
	--button-hover-bg-color: #323743;
	--button-focus-bg-color: #b73235;
	--button-focus-color: #fff;
	--button-border-radius: 90px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo33}) no-repeat left center;
	--application-logo-size: auto 50px;
	--application-logo-mobile-size: auto 38px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 0px solid transperent
	--login-bg-color:#fff;
        `,
	},
	{
		id: 34,
		variables: `
    --header-color: #fff;
	--side-menu-bg-color: #438877;
	--side-menu-bg-mobile-color: #438877;
	--userprogress-bg-deep: #ed1f23;
	--userprogress-bg-light: #f7901d;
	--userprogress-color: #000;
	--section-title-color: #000;
	--header-nav-color: #000;
	--header-nav-bg-completed: #ee2a23;
	--side-menu-color: #fff;
	--button-color: #fff;
	--button-bg-color: #f68d1e;
	--button-hover-color: #fff;
	--button-hover-bg-color: #da7b1a;
	--button-focus-bg-color: #f68d1e;
	--button-focus-color: #fff;
	--button-border-radius: 90px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo34}) no-repeat left center;
	--application-logo-size: auto 33px;
	--application-logo-mobile-size: auto 28px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 1px solid #e0e0e0;
	--login-bg-color:#fff;
        `,
	},

	{
		id: 38,
		variables: `
    --header-color: #e5493d;
	--side-menu-bg-color: #1e427b;
	--side-menu-bg-mobile-color: #1e427b;
	--userprogress-bg-deep: #b72e24;
	--userprogress-bg-light: #e5493d;
	--userprogress-color: #000;
	--section-title-color: #000;
	--header-nav-color: #fff;
	--header-nav-bg-completed: #fff;
	--side-menu-color: #fff;
	--button-color: #fff;
	--button-bg-color: #1b417b;
	--button-hover-color: #fff;
	--button-hover-bg-color: #b72e24;
	--button-focus-bg-color: #1b417b;
	--button-focus-color: #fff;
	--button-border-radius: 90px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo38}) no-repeat left center;
	--application-logo-size: auto 32px;
	--application-logo-mobile-size: auto 25px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 0px solid transperent;
	--login-bg-color:#fff;
        `,
	},
	{
		id: 39,
		variables: `
    --header-color: #fff;
	--side-menu-bg-color: #e0e0e0;
	--side-menu-bg-mobile-color: #e0e0e0;
	--userprogress-bg-deep: #0e2837;
	--userprogress-bg-light: #286c91;
	--userprogress-color: #000;
	--section-title-color: #000;
	--header-nav-color: #000;
	--header-nav-bg-completed: #e94d65;
	--side-menu-color: #000;
	--button-color: #fff;
	--button-bg-color: #25475c;
	--button-hover-color: #fff;
	--button-hover-bg-color: #34b1e2;
	--button-focus-bg-color: #25475c;
	--button-focus-color: #fff;
	--button-border-radius: 2px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo39}) no-repeat left center;
	--application-logo-size: auto 17px;
	--application-logo-mobile-size: auto 14px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 1px solid #e0e0e0;
	--login-bg-color:#fff;
        `,
	},
	{
		id: 40,
		variables: `
    --header-color: #fff;
	--side-menu-bg-color: #e5e5e5;
	--side-menu-bg-mobile-color: #e5e5e5;
	--userprogress-bg-deep: #0b859b;
	--userprogress-bg-light: #10b6c2;
	--userprogress-color: #000;
	--section-title-color: #000;
	--header-nav-color: #000;
	--header-nav-bg-completed: #cbab83;
	--side-menu-color: #000;
	--button-color: #fff;
	--button-bg-color: #108196;
	--button-hover-color: #fff;
	--button-hover-bg-color: #0c5c6b;
	--button-focus-bg-color: #108196;
	--button-focus-color: #fff;
	--button-border-radius: 2px;
	--question-color: #a0a0a0;
	--application-logo: url(${logo40}) no-repeat left center;
	--application-logo-size: auto 50px;
	--application-logo-mobile-size: auto 38px;
	--disabled-color: #a0a0a0;
	--button-disabled-color: #a0a0a0;
	--header-border: 3px solid #e5e5e5;
	--login-bg-color:#fff;
        `,
	},

	{
		id: 56,
		variables: `
    --header-color: #fff;
	--side-menu-bg-color: url(${sidemenu_bg56}) no-repeat right bottom 130px, #35a79b;
	--side-menu-bg-mobile-color: #35a79b;
	--userprogress-bg-deep: #35a79b66;
	--userprogress-bg-light: #35a79b66;
	--userprogress-color: #363436;
	--section-title-color: #35a79b;
	--header-nav-color: #303030;
	--header-nav-bg-completed: #35a79b;
	--side-menu-color: #fff;
	--button-color: #fff;
	--button-bg-color: #35a79b;
	--button-hover-color: #35a79b;
	--button-hover-bg-color: rgba(53, 167, 155, 0.15);
	--button-focus-bg-color: #35a79b;
	--button-focus-color: #fff;
	--button-border-radius: 3px;
	--question-color: #303030;
	--application-logo: url(${logo56}) no-repeat left center;
	--application-logo-size: auto 45px;
	--application-logo-mobile-size: auto 33px;
	--disabled-color: #c4c4c4;
	--button-disabled-color: #c4c4c4;
	--header-border: 1px solid rgba(0, 0, 0, 0.25);
	--login-bg-color:#f4f4f4;
        `,
	},

	{
		id: 57,
		variables: `
    --header-color: #fff;
	--side-menu-bg-color: url(${sidemenu_bg57}) no-repeat right bottom 130px, #791214;
	--side-menu-bg-mobile-color: #791214;
	--userprogress-bg-deep: #79121466;
	--userprogress-bg-light: #79121466;
	--userprogress-color: #363436;
	--section-title-color: #303030;
	--header-nav-color: #303030;
	--header-nav-bg-completed: #c68c38;
	--side-menu-color: #fff;
	--button-color: #fff;
	--button-bg-color: #c68c38;
	--button-hover-color: #c68c38;
	--button-hover-bg-color: rgba(198, 140, 56, 0.15);
	--button-focus-bg-color: #c68c38;
	--button-focus-color: #fff;
	--button-border-radius: 3px;
	--question-color: #303030;
	--application-logo: url(${logo57}) no-repeat left center;
	--application-logo-size: auto 36px;
	--application-logo-mobile-size: auto 29px;
	--disabled-color: #c4c4c4;
	--button-disabled-color: #c4c4c4;
	--header-border: 1px solid rgba(0, 0, 0, 0.25);
	--login-bg-color:#fff;
        `,
	},
];
