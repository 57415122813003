import { LANGAUGE_ACTIONS } from './actionTypes';

const LangaugeReducer = (language, action) => {
	switch (action.type) {
		case LANGAUGE_ACTIONS.CHANGE_LANG:
			return parseInt(action.payload);

		default:
			return language;
	}
};

export default LangaugeReducer;
