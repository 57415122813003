const APP_URL = window.Settings.APP_URL;

export const QUESTION_TYPES = Object.freeze({
	Text: { id: 1, htmlType: 'text', regex: /^(?!\s*$)[A-Za-z0-9 ,/'’\u00C0-\u00FF!@#\$%\^\&*\(\)+=._:`-]+$/ },
	RadioButton: { id: 2, htmlType: 'radio' },
	DDL: {
		id: 3,
		placeholders: [
			{ languageId: 25, inputText: `Please choose`, inputValue: -1 },
			{ languageId: 34, inputText: `Choisissez s'il vous plaît`, inputValue: -1 },
		],
	},
	PlusMinus: { id: 4 },
	Upload: { id: 5 },
	DDL_Multiselect: { id: 6 },
	TextArea: {
		id: 7,
		htmlType: 'textarea',
		regex:
			/^[A-Za-z0-9 ,.'’\"“”?!@#$%^&*()_+\-=\[\]{};:«»\\|<>\/`~·\u00C0-\u00FF\u2013\u2014\u0152\u0153\u00C6\u00E6\u202F\s\r\u2026\u2022]+$/,
	},
	Info: { id: 8 },
	TextNumber: { id: 9, htmlType: 'number', regex: /^(?!-*$)[0-9\-]+$/ },
	LettersOnly: { id: 10, htmlType: 'text', regex: /^(?!\s*$)[A-Za-z ]+$/ },
	Email: { id: 11, htmlType: 'email', regex: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/ },
	CheckBox: { id: 12, htmlType: 'checkbox' },
	DynamicDDL: { id: 13 },
	UploadPhoto: { id: 14 },
	MultiDynamicDDL: { id: 15 },
	UploadCV: { id: 16 },
	MultiLimitedDDL: { id: 17 },
	DatePicker: { id: 18 },
	Month: { id: 19 },
	UploadGCDocs: { id: 20 },
	LettersAndNumbers: { id: 21, htmlType: 'text', regex: /^[A-Za-z0-9]+$/ },
	Passport: { id: 22, htmlType: 'text', regex: /^[A-Za-z0-9]{1,15}$/ },
	MonthPickerDDL: { id: 27 },
	Score: { id: 28, generalTranslationKey: 'Express Entry Points' },
	ScoreQuebec: { id: 29, generalTranslationKey: 'Quebec Skilled Worker Points' },
});

export const BOOL_VALUES = Object.freeze({
	True: 'true',
	False: 'false',
});

export const Languages = Object.freeze({
	Arabic: { Name: 'Arabic', Id: 5, ISO: 'AR', isRtl: true },
	English: { Name: 'English', Id: 25, ISO: 'EN', isRtl: false, logoutText: 'logout' },
	Spanish: { Name: 'Spanish', Id: 27, ISO: 'ES', isRtl: false },
	French: { Name: 'French', Id: 34, ISO: 'FR', isRtl: false, logoutText: 'Se déconnecter' },
	Russian: { Name: 'Russian', Id: 95, ISO: 'RU', isRtl: false },
	Turkish: { Name: 'Turkish', Id: 123, ISO: 'TR', isRtl: false },
	Portuguese: { Name: 'Portuguese', Id: 90, ISO: 'PT', isRtl: false },
});

export const FILE_TYPES = Object.freeze({
	ApplicantCV: { Id: 1 },
	SpouseCV: { Id: 2 },
	ApplicantPhoto: { Id: 3 },
	SpousePhoto: { Id: 4 },
	PdfResult: { Id: 5 },
	ExtraFileStudentsVisa: { Id: 6 },
	JobLawyerFile: { Id: 7 },
	Passport: { Id: 8 },
	ChildrenPics: { Id: 9 },
});

export const ERROR_MESSAGE = Object.freeze({
	en: `An error has happened, please try again in a few minutes.`,
	es: `Ha ocurrido un  error, por favor intente de nuevo en unos minutos.`,
	ru: `Произошла неизвестная ошибка. Пожалуйста, повторите попытку через несколько минут.`,
	fr: `Une erreur s’est produite, merci de réessayer dans quelques minutes.`,
	ar: `لقد حدث خطأ ما، نرجو المحاولة مرة أخرى خلال بضعة دقائق.`,
	tr: `Bir hata oldu, lütfen birkaç dakika sonra tekrar deneyin.`,
});

export const LOGIN_PAGE = Object.freeze({
	en: `https://${APP_URL}/login/`,
	es: `https://${APP_URL}/login/?lang=es`,
	ru: `https://${APP_URL}/login/?lang=ru`,
	fr: `https://${APP_URL}/login/?lang=fr`,
	ar: `https://${APP_URL}/login/?lang=ar`,
	tr: `https://${APP_URL}/login/?lang=tr`,
});

export const ROLES = Object.freeze({
	User: 0,
	Lawyer: 1,
	CSR: 2,
	0: 'User',
	1: 'Lawyer',
	2: 'CSR',
});
