import { useState, useRef, useEffect, useMemo } from 'react';
import { useAppContext } from 'services/context';
//import ExifOrientationImg from "react-exif-orientation-img";
import Modal from 'components/Modal';
import { FILE_TYPES, Languages } from 'constants/index.js';
import InfoBox from 'components/InfoBox';
import { useScreenSize, MOBILE_SIZE } from 'services/utils';
import './style.css';
import { Base64 } from 'js-base64';

const UploadImage = ({ question, questionType, showValidationError, isValid, SetClientAnswer }) => {
	const { lang, IsLocked, currentTab, Api } = useAppContext();
	const [uploadError, setUploadError] = useState('');
	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const screen = useScreenSize();

	const Description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, []);

	const UploadText = useMemo(() => {
		const text = currentTab.GeneralTranslation.Upload.find(txt => txt.LanguageId === lang);
		if (text) return text.Value;
		return currentTab.GeneralTranslation.Upload.find(txt => txt.LanguageId === Languages.English.Id).Value;
	}, []);

	const LanguageISO = Object.values(Languages).find(lan => lan.Id === lang).ISO;

	const [file, setFile] = useState();
	const [loading, setLoading] = useState(false);
	const uploaderRef = useRef();
	const [isOpen, setIsOpen] = useState(false);

	const GetFileById = FileId => {
		if (FileId) {
			setLoading(true);
			Api.GetFile(FileId, questionType.id)
				.then(async file => {
					const url = window.URL.createObjectURL(file.blob);
					const fileDownload = file.filename.replace(/"/g, '');
					const filename = fileDownload.includes('=?utf-8?')
						? Base64.decode(fileDownload.substring(fileDownload.indexOf('B?') + 2, fileDownload.indexOf('?=')))
						: fileDownload;
					setFile({ href: url, download: filename });
				})
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		const FileId = question.ClientAnswer.InputValue;
		GetFileById(FileId);

		// eslint-disable-next-line
	}, [question.ClientAnswer.InputValue]);

	const fileChanged = async e => {
		setLoading(true);
		let formData = new FormData();
		formData.append('file', e.target.files[0]);
		try {
			setUploadError('');
			const res = await Api.UploadFile(
				formData,
				question.ApplicationFileTypeId ? question.ApplicationFileTypeId : 1,
				currentTab.TabId,
				question.QuestionId
			);
			if (!Number.isInteger(res)) {
				setLoading(false);
				setUploadError(JSON.parse(res));
				return;
			}
			SetClientAnswer(question.QuestionId, res);
			GetFileById(res);
		} catch (error) {
			uploaderRef.current.value = ''; // show upload error
			setLoading(false);
		}
	};

	const toggleModal = () => {
		setIsOpen(!isOpen);
	};

	const Details = () => {
		try {
			if (question.ClientAnswer.AnswerExtraText) {
				const obj = JSON.parse(question.ClientAnswer.AnswerExtraText);

				if (obj.RemarkJson || obj.RemarkStatus) {
					return (
						<div>
							{obj.RemarkStatus}{' '}
							{obj.RemarkJson &&
								JSON.parse(obj.RemarkJson)
									.filter(remark => remark.RemarkId === 17)
									.map((answer, index) => <div key={index} dangerouslySetInnerHTML={{ __html: answer.RemarkFullDesc }}></div>)}
						</div>
					);
				}
			}
		} catch (error) {}
		return null;
	};

	return (
		<div className="UploadImage">
			<div className="UploadImage__title">
				{Description.QuestionTitle || Description.QuestionText}
				{(Description.QuestionTitle || Description.QuestionText) && question.IsRequired && <span className="required">*</span>}
				{[FILE_TYPES.ApplicantPhoto.Id, FILE_TYPES.SpousePhoto.Id].some(id => id == question.ApplicationFileTypeId) && (
					<InfoBox message={Description.QuestionInformation} QuestionId={question.QuestionId} />
				)}
				&nbsp;
			</div>

			<div className="UploadImage__container">
				{file && (
					<div className="UploadImage__image">
						<a className="UploadImage__filename" href={file.href} download={file.download?.replace(/"/g, '')}>
							{!loading && <img src={file.href} alt="profile" />}
						</a>
					</div>
				)}

				<div className="UploadImage__details">
					<div className="UploadImage__input">
						{loading ? (
							<span className="loader secondary" />
						) : (
							<label htmlFor={`UploadImage__${question.QuestionId}`} className={`button ${!locked ? 'secondary' : 'disabled'}`}>
								{UploadText}
							</label>
						)}
					</div>
				</div>
			</div>

			<input
				ref={uploaderRef}
				id={`UploadImage__${question.QuestionId}`}
				type="file"
				accept={`image/*${question.FileTypeExtensionArray.join(',')}`}
				onChange={fileChanged}
				disabled={locked}
			/>
			{((showValidationError && !isValid(question, questionType)) || uploadError) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{
						__html: uploadError
							? uploadError
							: Description?.QuestionNoteTextArray[Description?.QuestionNoteTextArray.length - 1]?.join('<br/>') || '',
					}}
				></label>
			)}

			<Modal isOpen={isOpen} setVisible={setIsOpen}>
				<>
					{Description?.QuestionInformation && <div>{Description.QuestionInformation}</div>}
					<div className="modal__close" onClick={toggleModal}>
						X
					</div>
				</>
			</Modal>
		</div>
	);
};

export default UploadImage;
