import { CURRENT_TAB_ACTIONS } from './actionTypes';

const CurrentTabReducer = (currentTab, action) => {
	switch (action.type) {
		case CURRENT_TAB_ACTIONS.SET_CURRENT_TAB:
			return action.payload;
		default:
			return currentTab;
	}
};

export default CurrentTabReducer;
