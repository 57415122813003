import { useEffect, useMemo } from 'react';
import InfoBox from 'components/InfoBox';
import { useAppContext } from 'services/context';
import { Languages } from 'constants/index.js';

import './style.css';

const Score = ({ question, SetClientAnswer, questionType, showValidationError, isValid }) => {
	const { lang, IsLocked, currentTab } = useAppContext();
	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const scoreTitle = useMemo(() => {
		if (!currentTab) return '';
		try {
			const key = questionType.generalTranslationKey || '';
			const _scoreTitle = currentTab.GeneralTranslation[key]?.find(obj => obj.LanguageId === lang) || '';
			if (_scoreTitle) return _scoreTitle.Value;
			return currentTab.GeneralTranslation[key]?.find(obj => obj.LanguageId === Languages.English.Id).Value || '';
		} catch (ex) {
			console.log('score title id:' + question.QuestionId, ex);
			return '';
		}
	}, [lang, currentTab]);

	const options = useMemo(() => {
		if (!question?.QuestionScore?.ScoreValueArray) {
			console.log('no question answers ' + question.QuestionId);
			return [];
		}

		const _options = question.QuestionScore.ScoreValueArray.map(score => ({
			IsDefault: false,
			value: score,
			label: score,
		}));

		return _options;
	}, [question, question?.QuestionScore?.ScoreValueArray, question?.ClientAnswer?.AnswerScore]);

	const jsxOptions = useMemo(() => {
		return options?.map(opt => (
			<option disabled={opt.IsDefault} key={opt.value} value={opt.value}>
				{opt.label}
			</option>
		));
	}, [options]);

	useEffect(() => {
		const option = options?.find(answer => answer.value === question.ClientAnswer.AnswerScore);
		const defaultOption = options?.find(answer => answer.IsDefault) || options[0];
		if (!option) SetClientAnswer(question.QuestionId, defaultOption?.value || null);
	}, []);

	const onUserSelect = e => SetClientAnswer(question.QuestionId, e.target.value, questionType.id);

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	const ScoreContainerValue = useMemo(() => {
		try {
			const selectedAnswer = options.find(answer => answer.value === question.ClientAnswer.AnswerScore);
			if (selectedAnswer) return selectedAnswer.value;
			const defaultAnswer = options.find(answer => answer.IsDefault);
			if (defaultAnswer) return defaultAnswer.value;
			return undefined;
		} catch (error) {
			return undefined;
		}
	}, [question, options]);

	return (
		<div className="Score">
			{scoreTitle && (
				<div className="Score__title">
					{scoreTitle || ''}
					{scoreTitle !== '' && question.IsRequired && <span className="required">*</span>}
					&nbsp;
					{description?.QuestionInformation && description.QuestionInformation.length !== 0 && (
						<InfoBox message={description.QuestionInformation} QuestionId={question.QuestionId} />
					)}
				</div>
			)}

			<div className="Score__container">
				<select disabled={locked} onChange={onUserSelect} value={ScoreContainerValue}>
					{jsxOptions}
				</select>
			</div>

			{showValidationError && !isValid(question, questionType) && (
				<label className="validation_error" dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') }}></label>
			)}
		</div>
	);
};

export default Score;
