import React, { useEffect, useState, useRef, useMemo } from 'react';

import { Prompt } from 'react-router-dom';

import { useAppContext } from 'services/context';

import Input from 'components/Form/Input';
import RadioGroup from 'components/Form/RadioGroup';
import MultiDDL from 'components/Form/MultiDDL';
import DDL from 'components/Form/DDL';
import Checkbox from 'components/Form/Checkbox';
import Textarea from 'components/Form/Textarea';
import MonthPicker from 'components/Form/MonthPicker';
import DatePicker from 'components/Form/DatePicker';
import AddMore from 'components/Form/AddMore';
import Upload from 'components/Form/Upload';
import UploadImage from 'components/Form/UploadImage';
import WelcomeTab from 'containers/WelcomeTab';
import Info from 'components/Form/Note';
import MonthPickerDDL from 'components/Form/MonthPicker/MonthPickerDDL';
import Score from 'components/Form/Score';
import Modal from 'components/Modal';
import Iframe from 'components/Iframe';

import { findDuplicateByPropName, IS_MOBILE, isNumber } from 'services/utils';

import { QUESTION_TYPES, Languages } from 'constants/index.js';
import { SIDETABS_ACTIONS } from 'services/reducers/actionTypes';

import './style.css';

const WelcomeTabId = parseInt(process.env.REACT_APP_WELCOME_TAB);
const SummaryTabId = parseInt(process.env.REACT_APP_SUMMARY_TAB);
const ADD_ANOTHER_IDS = process.env.REACT_APP_ADD_ANOTHER_IDS || '';

const Tab = ({ history, match }) => {
	const {
		lang,
		SetTab,
		SaveTab,
		sideTabs,
		IsLocked,
		setSideTabs,
		globalAppTabs,
		IsCSR,
		isServiceAgreementModalOpen,
		setIsServiceAgreementModalOpen,
	} = useAppContext();

	const [tabId, setTabId] = useState();
	const [showValidationError, setValidationError] = useState(false);
	const [tab, setTab] = useState();
	const [isDirty, setDirty] = useState(false);
	const [loading, setLoading] = useState(false);

	//save the from date questions data in the MonthPicker or MonthPickerDDL components
	const [fromDate, setFromDate] = useState([]);

	const tabRef = useRef(null);

	const serviceAgreementUrl = useMemo(() => {
		try {
			if (!sideTabs) return '';
			if (!sideTabs.UniqueDetails) return '';
			return sideTabs.UniqueDetails.ServiceAgreementUrl;
		} catch (error) {
			return '';
		}
	}, [sideTabs]);

	const didSignServiceAgreement = useMemo(() => {
		try {
			if (!sideTabs) return true;
			if (!sideTabs.UniqueDetails) return true;
			return sideTabs.UniqueDetails.DidSignServiceAgreement && serviceAgreementUrl;
		} catch (error) {
			return true;
		}
	}, [sideTabs, serviceAgreementUrl]);

	const allowAddAnother = useMemo(() => {
		try {
			if (!tab) return false;
			const ids = ADD_ANOTHER_IDS.split(',');
			const allowed = ids.includes(tab?.TabId.toString());
			return allowed;
		} catch (error) {
			return false;
		}
	}, [tab]);

	const isQuestionEditable = useMemo(() => {
		return tab?.TabSections?.some(tabSection =>
			tabSection.SectionQuestions.filter(question => question.IsActive).some(question => question.IsEditable)
		);
	}, [tab]);

	const activeSections = useMemo(() => {
		if (!tab) return;
		return tab?.TabSections?.filter(section => section.IsActive).sort((a, b) => a.OrderInTab - b.OrderInTab);
	}, [tab]);

	const addAnotherSections = useMemo(() => {
		try {
			if (!tab || !allowAddAnother || !tab?.TabSections) return [];
			const duplicateSectionNames = findDuplicateByPropName(tab?.TabSections, 'SectionName');

			//the commented code supports just one group of duplicates
			// let _addAnotherSections = tab?.TabSections.map(_section =>
			// 	duplicateSectionNames.includes(_section['SectionName']) ? _section.ApplicationTabSectionId : undefined
			// );
			// _addAnotherSections = _addAnotherSections.filter(_section => _section);

			let _addAnotherSections = tab?.TabSections.reduce((acc, _section) => {
				if (duplicateSectionNames.includes(_section['SectionName'])) {
					if (!acc[_section.SectionName]) acc[_section.SectionName] = [];
					acc[_section.SectionName].push(_section.ApplicationTabSectionId);
				}
				return acc;
			}, {});
			//the  code above supports  groups of duplicates
			return _addAnotherSections;
		} catch (error) {
			console.log('error', error);
			// the commented code supports just one group of duplicates
			// return [];
			return {};
			//the code above supports  groups of duplicates
		}
	}, [tab, allowAddAnother]);

	//the  code bellow supports groups of duplicates
	const activeSectionsByGroup = useMemo(() => {
		if (!activeSections || !addAnotherSections) return;
		const _addAnotherSections = { ...addAnotherSections };

		Object.keys(addAnotherSections).forEach(key => {
			_addAnotherSections[key] = _addAnotherSections[key].filter(sectionId =>
				activeSections.some(section => sectionId === section.ApplicationTabSectionId)
			);
		});
		return _addAnotherSections;
	}, [activeSections, addAnotherSections]);

	const lastAddAnotherActiveSection = useMemo(() => {
		try {
			if (!addAnotherSections || !tab.TabSections) return null;
			// the commented code supports just one group of duplicates
			// const lastActiveSection = tab.TabSections.filter(
			// 	section => addAnotherSections.includes(section.ApplicationTabSectionId) && section.IsActive === true
			// ).pop();
			// return lastActiveSection;
			let lastActiveSection = {};
			Object.keys(addAnotherSections).forEach(key => {
				const lastActiveSectionByKey = tab.TabSections.filter(
					section => addAnotherSections[key].includes(section.ApplicationTabSectionId) && section.IsActive === true
				).pop();
				lastActiveSection[key] = lastActiveSectionByKey;
			});
			//the code above supports  groups of duplicates
			return lastActiveSection;
		} catch (error) {
			return null;
		}
	}, [tab, addAnotherSections]);

	const firstAddAnotherActiveSection = useMemo(() => {
		try {
			if (!addAnotherSections || !tab.TabSections) return null;
			// the commented code supports just one group of duplicates
			// const firstSection = tab.TabSections.filter(
			// 	section => addAnotherSections.includes(section.ApplicationTabSectionId) && section.IsActive === true
			// ).shift();
			// return firstSection;
			let firstSection = {};
			Object.keys(addAnotherSections).forEach(key => {
				const firstSectionByKey = tab.TabSections.filter(
					section => addAnotherSections[key].includes(section.ApplicationTabSectionId) && section.IsActive === true
				).shift();
				firstSection[key] = firstSectionByKey;
			});
			//the code above supports  groups of duplicates
			return firstSection;
		} catch (error) {
			return null;
		}
	}, [tab, addAnotherSections]);

	const isLoadingTab = useMemo(() => {
		return !tab || tab.TabId !== tabId;
	}, [tab, tabId]);

	const locked = useMemo(() => {
		return IsLocked && !isQuestionEditable;
	}, [isQuestionEditable, IsLocked]);

	const addMoreText = useMemo(() => {
		if (!tab) return;
		const text = tab.GeneralTranslation?.AddMore.find(description => description.LanguageId === lang) || '';
		if (text) return text.Value;
		return tab.GeneralTranslation?.AddMore.find(description => description.LanguageId === Languages.English.Id).Value || '';
	}, [tab, lang]);

	const isLastTab = useMemo(() => {
		if (!tab) return false;
		if (IsCSR && tab.TabId !== SummaryTabId) return false;
		const _incompleteTabs = sideTabs?.TabLayoutList?.filter(_sidetab => _sidetab.IsComplete === false);
		return _incompleteTabs?.length === 0 || (_incompleteTabs?.length === 1 && tab.TabId === _incompleteTabs[0]?.TabId) || false;
	}, [sideTabs, tab]);

	const submitMyApplicationText = useMemo(() => {
		if (!tab?.GeneralTranslation?.SubmitMyApplication) return 'Submit My Application';
		const text = tab.GeneralTranslation?.SubmitMyApplication.find(obj => obj.LanguageId === lang) || '';
		if (text) return text.Value;
		return tab.GeneralTranslation?.SubmitMyApplication.find(obj => obj.LanguageId === Languages.English.Id).Value || '';
	}, [tab, lang]);

	const saveAndContinueText = useMemo(() => {
		if (!tab?.GeneralTranslation?.SaveAndContinue) return 'Save and Continue';
		const text = tab.GeneralTranslation.SaveAndContinue.find(obj => obj.LanguageId === lang) || '';
		if (text) return text.Value;
		return tab.GeneralTranslation.SaveAndContinue.find(obj => obj.LanguageId === Languages.English.Id).Value || '';
	}, [tab, lang]);

	const buttonUrl = useMemo(() => {
		const _buttonUrl = tab?.ButtonText?.find(ButtonText => ButtonText.LanguageId === lang);
		if (!_buttonUrl || !_buttonUrl.Url) return '';
		let gid;
		if (!window.gid) {
			const _url = new URL(window.location.href);
			gid = _url.searchParams.get('gid');
		} else {
			gid = window.gid;
		}
		const url = `${window.Settings.API_URL}/${_buttonUrl.Url}?gid=${gid}&lng=${lang}`;

		return url;
	}, [tab, lang]);

	// SCORE TABLE
	const selectionFactorsTitle = useMemo(() => {
		if (!tab?.summaryTextTraslations || !tab?.summaryTextTraslations['Selection factors']) return 'Selection factors';
		const text = tab.summaryTextTraslations['Selection factors'].find(obj => obj.LanguageId === lang) || '';
		if (text) return text.Value;
		return tab.summaryTextTraslations['Selection factors'].find(obj => obj.LanguageId === Languages.English.Id).Value || '';
	}, [tab, lang]);

	const yourPointsTitle = useMemo(() => {
		if (!tab?.summaryTextTraslations || !tab?.summaryTextTraslations['Your Points']) return 'Your Points';
		const text = tab.summaryTextTraslations['Your Points'].find(obj => obj.LanguageId === lang) || '';
		if (text) return text.Value;
		return tab.summaryTextTraslations['Your Points'].find(obj => obj.LanguageId === Languages.English.Id).Value || '';
	}, [tab, lang]);

	const maximumPointsTitle = useMemo(() => {
		if (!tab?.summaryTextTraslations || !tab?.summaryTextTraslations['Maximum Points']) return 'Maximum Points';
		const text = tab.summaryTextTraslations['Maximum Points'].find(obj => obj.LanguageId === lang) || '';
		if (text) return text.Value;
		return tab.summaryTextTraslations['Maximum Points'].find(obj => obj.LanguageId === Languages.English.Id).Value || '';
	}, [tab, lang]);

	const summaryScoreTable = useMemo(() => {
		if (!tab || !tab.summaryScoreTable || !tab.summaryScoreTable?.length) return null;
		let total = 0,
			maximumPointsTotal = 0;
		return (
			<div className="summaryScoreTable">
				<div className="summaryScoreTable__header">
					<div>{selectionFactorsTitle}</div>
					<div>{yourPointsTitle}</div>
					<div>{maximumPointsTitle}</div>
				</div>
				<div className="summaryScoreTable__body">
					{tab.summaryScoreTable.map(({ Score, MaximumPoints, ScoreCategoryDescriptions }) => {
						let scoreCategory = ScoreCategoryDescriptions.find(obj => obj?.Language?.Id === lang);
						let scoreCategoryName = scoreCategory?.ScoreCategoryName || '';
						if (!scoreCategory) scoreCategory = ScoreCategoryDescriptions.find(obj => obj?.Language?.Id === Languages.English.Id);
						total += Score;
						maximumPointsTotal += MaximumPoints;
						return (
							<div className={`summaryScoreTable__body__row ${Score > MaximumPoints ? 'over_max_point' : ''}`}>
								<div>{scoreCategoryName}</div>
								<div>{Score}</div>
								<div className="maximum">{MaximumPoints}</div>
							</div>
						);
					})}
					<div className="summaryScoreTable__body__row summaryScoreTable__body__row--total">
						<div>Total</div>
						<div>{total}</div>
						<div className="maximum">{maximumPointsTotal}</div>
					</div>
				</div>
			</div>
		);
	}, [tab, selectionFactorsTitle, maximumPointsTitle, yourPointsTitle, lang]);

	const summaryScoreTableQSW = useMemo(() => {
		if (!tab || !tab.summaryScoreTableQSW || !tab.summaryScoreTableQSW?.length) return null;
		let total = 0,
			maximumPointsTotal = 0;
		return (
			<div className="summaryScoreTableQSW">
				<div className="summaryScoreTableQSW__header">
					<div>{selectionFactorsTitle}</div>
					<div>{yourPointsTitle}</div>
					<div>{maximumPointsTitle}</div>
				</div>

				<div className="summaryScoreTableQSW__body">
					{tab.summaryScoreTableQSW.map(({ Score, MaximumPoints, ScoreCategoryDescriptions }) => {
						let scoreCategory = ScoreCategoryDescriptions.find(obj => obj?.Language?.Id === lang);
						if (!scoreCategory) scoreCategory = ScoreCategoryDescriptions.find(obj => obj?.Language?.Id === Languages.English.Id);

						const scoreCategoryName = scoreCategory?.ScoreCategoryName || '';
						total += Score;
						maximumPointsTotal += MaximumPoints;
						return (
							<div className={`summaryScoreTableQSW__body__row ${Score > MaximumPoints ? 'over_max_point' : ''}`}>
								<div>{scoreCategoryName}</div>
								<div>{Score}</div>
								<div className="maximum">{MaximumPoints}</div>
							</div>
						);
					})}
				</div>
				<div className="summaryScoreTableQSW__body__row summaryScoreTableQSW__body__row--total">
					<div>Total</div>
					<div>{total}</div>
					<div className="maximum">{maximumPointsTotal}</div>
				</div>
			</div>
		);
	}, [tab, selectionFactorsTitle, maximumPointsTitle, yourPointsTitle, lang]);

	useEffect(() => {
		async function updateCurrentTab() {
			try {
				let _tabId = match.params.id;
				if (!_tabId && sideTabs.Tabs && Array.isArray(sideTabs.Tabs)) {
					_tabId = sideTabs.Tabs[0].TabId;
				}
				setDirty(false);
				setTabId(parseInt(_tabId));
				setValidationError(false);
				const _tab = await SetTab(parseInt(_tabId));
				setTab(_tab);
				tabRef.current?.scrollTo(0, 0);
			} catch (err) {
				console.log(`update current tab ${err}`);
			}
		}
		updateCurrentTab();
	}, [match.params.id, globalAppTabs]);

	useEffect(() => {
		const handleMessage = event => {
			if (event.data.type === 'UPDATE_STATE_AGREEMENT') {
				setIsServiceAgreementModalOpen(false);
				handleSaveTab();
			}
		};

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [tab]);

	const setClientAnswer = (QuestionId, newAnswer, questionTypeId) => {
		let _currentTab = { ...tab };
		let _dirty = true;
		for (const section of _currentTab.TabSections) {
			for (const question of section.SectionQuestions) {
				if (question.QuestionId === QuestionId) {
					_dirty = newAnswer !== question.ClientAnswer.InputValue && newAnswer !== question.MultipleAnswerList; //check that the question really changed
					if (newAnswer == null) {
						question.MultipleAnswerList = newAnswer;
						question.ClientAnswer.InputValue = newAnswer;
					} else {
						if (Array.isArray(newAnswer)) {
							question.MultipleAnswerList = newAnswer.map(ans => ({ ...question.ClientAnswer, InputValue: ans }));
						} else {
							question.ClientAnswer.InputValue = newAnswer;
						}
					}
					//support score extra field
					if (questionTypeId === QUESTION_TYPES.Score.id || questionTypeId === QUESTION_TYPES.ScoreQuebec.id) {
						question.ClientAnswer.AnswerScore = newAnswer;
					}
				}
			}
		}

		setTab(_currentTab);
		setDirty(_dirty);
	};

	const emailVerify = question => {
		if (!question.ParentQuestionId || question.ParentQuestionId === 0) {
			return false;
		}
		const _tabSectionsQuestion = tab.TabSections.map(tabSection =>
			tabSection.SectionQuestions.find(sq => sq.QuestionId === question.ParentQuestionId)
		);
		const parentQuestion = _tabSectionsQuestion.find(sq => sq && sq.QuestionId === question.ParentQuestionId);

		return parentQuestion?.ClientAnswer?.InputValue === question.ClientAnswer.InputValue;
	};

	const recursiveCheckDependent = question => {
		if (!question.ParentQuestionId || question.ParentQuestionId === 0) {
			return true;
		}
		//to exclude email question
		if (question.IsRequired && question.QuestionType === QUESTION_TYPES.Email.id && question.ParentQuestionId !== 0) {
			return true;
		}

		const _tabSectionsQuestion = tab.TabSections.map(tabSection =>
			tabSection.SectionQuestions.find(sq => sq.QuestionId === question.ParentQuestionId)
		);
		const parentQuestion = _tabSectionsQuestion.find(sq => sq && sq.QuestionId === question.ParentQuestionId);
		let parentAnswer = parentQuestion?.ClientAnswer?.InputValue || parentQuestion?.MultipleAnswerList || '';

		if (parentQuestion?.QuestionType === QUESTION_TYPES.CheckBox.id && !parentAnswer) {
			parentAnswer = 'false';
		}

		return question.DependentOptionalValueArray.some(answer => answer == parentAnswer) && recursiveCheckDependent(parentQuestion);
	};

	const _renderQuestion = question => {
		// const validCheckDependent = recursiveCheckDependent(question);
		// if (!validCheckDependent) return null;

		const questionTitle = (() => {
			const text = question.QuestionDescriptions.find(desc => desc.Language.Id === lang) || '';
			if (text) return text.QuestionTitle;
			const englishText = question.QuestionDescriptions.find(desc => desc.Language.Id === Languages.English.Id);
			return englishText?.QuestionTitle || '';
		})();

		const globalProps = {
			question,
			SetClientAnswer: setClientAnswer,
			showValidationError,
			isValid: validateQuestion,
		};
		const key = question.QuestionId;
		switch (question.QuestionType) {
			case QUESTION_TYPES.Text.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Input questionType={QUESTION_TYPES.Text} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.Info.id:
				return (
					<QuestionWrapper questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Info question={question} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.RadioButton.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<RadioGroup questionType={QUESTION_TYPES.RadioButton} {...globalProps} />
					</QuestionWrapper>
				);
			case QUESTION_TYPES.Score.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Score questionType={QUESTION_TYPES.Score} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.ScoreQuebec.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Score questionType={QUESTION_TYPES.ScoreQuebec} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.DDL.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<DDL questionType={QUESTION_TYPES.DDL} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.DynamicDDL.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<DDL questionType={QUESTION_TYPES.DynamicDDL} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.Month.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<MonthPicker
							questionType={QUESTION_TYPES.Month}
							{...globalProps}
							fromDate={fromDate}
							setFromDate={setFromDate}
							saveFromDateInGlobalStateVariable={saveFromDateInGlobalStateVariable}
						/>
					</QuestionWrapper>
				);

			case QUESTION_TYPES.DatePicker.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<DatePicker questionType={QUESTION_TYPES.DatePicker} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.TextNumber.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Input questionType={QUESTION_TYPES.TextNumber} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.LettersOnly.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Input questionType={QUESTION_TYPES.LettersOnly} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.Email.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Input questionType={QUESTION_TYPES.Email} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.PlusMinus.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<AddMore questionType={QUESTION_TYPES.PlusMinus} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.TextArea.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Textarea questionType={QUESTION_TYPES.TextArea} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.DDL_Multiselect.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<MultiDDL questionType={QUESTION_TYPES.DDL_Multiselect} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.MultiDynamicDDL.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<MultiDDL questionType={QUESTION_TYPES.MultiDynamicDDL} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.CheckBox.id:
				return (
					<QuestionWrapper questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Checkbox questionType={QUESTION_TYPES.CheckBox} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.UploadPhoto.id:
				return (
					<QuestionWrapper questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<UploadImage questionType={QUESTION_TYPES.UploadPhoto} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.UploadGCDocs.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Upload questionType={QUESTION_TYPES.UploadGCDocs} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.Upload.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Upload questionType={QUESTION_TYPES.Upload} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.UploadCV.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Upload questionType={QUESTION_TYPES.UploadCV} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.Passport.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Input questionType={QUESTION_TYPES.Passport} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.LettersAndNumbers.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<Input questionType={QUESTION_TYPES.LettersAndNumbers} {...globalProps} />
					</QuestionWrapper>
				);

			case QUESTION_TYPES.MonthPickerDDL.id:
				return (
					<QuestionWrapper questionTitle={questionTitle} questionId={question.QuestionId} rowNumber={question.RowNumber} key={key}>
						<MonthPickerDDL
							questionType={QUESTION_TYPES.MonthPickerDDL}
							{...globalProps}
							fromDate={fromDate}
							setFromDate={setFromDate}
							saveFromDateInGlobalStateVariable={saveFromDateInGlobalStateVariable}
						/>
					</QuestionWrapper>
				);
			default:
				return <div key={key}>{question.QuestionType}</div>;
		}
	};

	const handleSaveTab = async () => {
		try {
			setDirty(false);
			setLoading(true);
			let nextTabId = await SaveTab(tab, IS_MOBILE);
			setLoading(false);
			if (!nextTabId) nextTabId = WelcomeTabId;

			const params = new URLSearchParams(history.location.search);
			if (window.gid) {
				params.set('gid', window.gid);
			}

			history.push({
				pathname: `/tab/${nextTabId}`,
				search: `?${params.toString()}`,
			});
		} catch (error) {
			setLoading(false);
		}
	};

	const validateQuestion = (question, questionType) => {
		if (!question.IsActive) return true;
		const validCheckDependent = recursiveCheckDependent(question);

		if (!validCheckDependent) return true;

		if (question.IsRequired && question.QuestionType === QUESTION_TYPES.Email.id && question.ParentQuestionId !== 0) {
			return emailVerify(question);
		}
		let inputVal = question.ClientAnswer.InputValue;
		inputVal = (isNumber(inputVal) && parseInt(inputVal)) || inputVal;
		const multiAnswers = question.MultipleAnswerList;
		if (question.IsRequired && questionType.id === QUESTION_TYPES.DDL.id && inputVal === -1) return false; //need to do it becuase Yarin was too lazy to change the default value to null

		if (question.IsRequired) {
			if ((!multiAnswers || (multiAnswers && multiAnswers.length === 0)) && !inputVal) return false;
		}

		if (inputVal) return questionType.regex ? questionType.regex.test(inputVal) : true;

		return true;
	};

	const validateForm = () => {
		return activeSections.every(tabSection =>
			tabSection.SectionQuestions.every(question =>
				validateQuestion(
					question,
					Object.values(QUESTION_TYPES).find(q => q.id === question.QuestionType)
				)
			)
		);
	};

	const onSubmit = async () => {
		if (locked) return;
		if (buttonUrl) {
			window.open(buttonUrl, '_blank');
			return;
		}
		const isValid = validateForm();
		setValidationError(!isValid); // if form not valid show validations messages
		if (isValid) {
			//service agreement
			//specical case only for canada application
			if (serviceAgreementUrl && !didSignServiceAgreement && tab.IsLastTab) {
				setIsServiceAgreementModalOpen(true);
				return;
			}
			handleSaveTab();
		} else {
			setTimeout(() => {
				const errorLabel = document.querySelector('.validation_error');
				if (errorLabel) {
					window.scrollTo(0, errorLabel.parentElement.offsetTop - 120);
				}
			});
		}
	};

	const title = tabSection => {
		const text = tabSection.SectionDescriptions.find(SectionDescription => SectionDescription.Language.Id === lang) || '';
		const englishText = tabSection.SectionDescriptions.find(SectionDescription => SectionDescription.Language.Id === Languages.English.Id);

		if (text) return text.DisplayName;
		return englishText?.DisplayName || '';
	};

	const renderDocumentText = () => {
		if (tab.DocTextTraslation && tab.DocTextTraslation.length !== 0) {
			const text = tab.DocTextTraslation.find(desc => desc.LanguageId === lang) || '';
			if (text) return text.Value;
			else {
				return tab.DocTextTraslation.find(desc => desc.LanguageId === Languages.English.Id).Value || '';
			}
		}
		return undefined;
	};

	//save the from Date question data (from the MonthPicker or MonthPickerDDL components) in the fromDate state
	const saveFromDateInGlobalStateVariable = fromQuestionData => {
		let _fromDate = [...fromDate];
		const currFromDateIndex = _fromDate.findIndex(currQuestion => currQuestion.questionId === fromQuestionData.questionId);
		_fromDate[currFromDateIndex] = fromQuestionData;
		return _fromDate;
	};

	const removeSection = sectionId => {
		setValidationError(false);
		const _tab = {
			...tab,
			TabSections: tab.TabSections.map(section =>
				section.ApplicationTabSectionId === sectionId
					? {
							...section,
							IsActive: false,
							SectionQuestions: section.SectionQuestions.map(q => ({
								...q,
								MultipleAnswerList: null,
								ClientAnswer: {
									AnswerDescriptions: null,
									AnswerExtraText: null,
									InputArrayID: 0,
									InputValue: null,
									IsDefault: false,
								}, // reseting client answer
							})),
					  }
					: section
			), // disabling the current section (used for child tab)
		};

		setTab(_tab);
	};

	const addSection = groupName => {
		if (loading) return;

		//the commented code supports just one group of duplicates
		// const notActiveSectionIndex = tab.TabSections.findIndex(section => section.IsActive === false);
		const notActiveSectionIndex = tab.TabSections.findIndex(
			section => section.IsActive === false && (!groupName || section.SectionName === groupName)
		);
		if (notActiveSectionIndex === -1) return;
		const sectionToBeAdded = tab.TabSections[notActiveSectionIndex];
		const _tab = {
			...tab,
			TabSections: tab.TabSections.sort((a, b) => {
				if (a.ApplicationTabSectionId === sectionToBeAdded.ApplicationTabSectionId) return 1;
				if (b.ApplicationTabSectionId === sectionToBeAdded.ApplicationTabSectionId) return -1;
				return 0;
			}).map((section, index) =>
				section.ApplicationTabSectionId === sectionToBeAdded.ApplicationTabSectionId ? { ...section, IsActive: true } : section
			),
		};

		setTab(_tab);
	};

	const closeMobileMenu = () => {
		if (sideTabs.IsMenuOpen) setSideTabs({ type: SIDETABS_ACTIONS.SET_MENU, payload: false });
	};

	const _handleCloseModalAndSubmitTab = value => {
		setIsServiceAgreementModalOpen(false);
		handleSaveTab();
	};

	if (isLoadingTab)
		return (
			<div className="Tab">
				<Skeleton />
			</div>
		);

	return (
		<div className="Tab" ref={tabRef} onClick={closeMobileMenu}>
			{tab.TabId === WelcomeTabId ? (
				<WelcomeTab onClickButton={onSubmit} />
			) : (
				<>
					{/* TABLES LOGIC */}
					{summaryScoreTable}
					{summaryScoreTableQSW}
					{activeSections.map((tabSection, idx) => {
						const groupName = tabSection.SectionName;

						//the commented code supports just one group of duplicates
						// const shouldAddAnotherSection = !locked && addAnotherSections?.includes(tabSection.ApplicationTabSectionId);

						const shouldAddAnotherSection =
							!locked &&
							Array.isArray(addAnotherSections[groupName]) &&
							addAnotherSections[groupName].includes(tabSection.ApplicationTabSectionId);
						//the code above supports  groups of duplicates

						//the commented code supports just one group of duplicates
						// const renderAddButton =
						// 	!locked &&
						// 	shouldAddAnotherSection &&
						// 	lastAddAnotherActiveSection.ApplicationTabSectionId === tabSection.ApplicationTabSectionId &&
						// 	activeSections.length < tab.TabSections.length;

						const renderAddButton =
							!locked &&
							shouldAddAnotherSection &&
							lastAddAnotherActiveSection[groupName].ApplicationTabSectionId === tabSection.ApplicationTabSectionId &&
							activeSectionsByGroup[groupName].length < addAnotherSections[groupName].length;
						//the code above supports  groups of duplicates

						//the commented code supports just one group of duplicates
						// const renderRemoveButton =
						// 	!locked &&
						// 	shouldAddAnotherSection &&
						// 	firstAddAnotherActiveSection.ApplicationTabSectionId !== tabSection.ApplicationTabSectionId;

						const renderRemoveButton =
							!locked &&
							shouldAddAnotherSection &&
							firstAddAnotherActiveSection[groupName].ApplicationTabSectionId !== tabSection.ApplicationTabSectionId;
						//the code above supports  groups of duplicates

						const activeAndValidCheckDependentQuestions = tabSection.SectionQuestions.filter(question => {
							if (!question.IsActive) return false;
							const validCheckDependent = recursiveCheckDependent(question);
							return validCheckDependent;
						});

						if (activeAndValidCheckDependentQuestions.length === 0) return null;

						return (
							<React.Fragment key={tabSection.ApplicationTabSectionId}>
								<div className="Tab__section" key={tabSection.ApplicationTabSectionId} s-id={tabSection.ApplicationTabSectionId}>
									<div className="title">
										{title(tabSection)}
										{renderRemoveButton && (
											<span onClick={() => removeSection(tabSection.ApplicationTabSectionId)} className="Tab__section__remove"></span>
										)}
									</div>
									<div className="Documents__Descriptions" dangerouslySetInnerHTML={{ __html: renderDocumentText() }}></div>
									<div className="questions">{activeAndValidCheckDependentQuestions.map(_renderQuestion)}</div>
								</div>
								{renderAddButton && (
									<div className="Tab__section mobile_padding">
										{/* the commented code supports just one group of duplicates */}
										{/* <div className={`button secondary ${loading ? 'disabled' : ''}`} onClick={addSection}> */}
										<div className={`button secondary ${loading ? 'disabled' : ''}`} onClick={() => addSection(groupName)}>
											<span className="icon icon__add">{addMoreText}</span>
										</div>
									</div>
								)}
							</React.Fragment>
						);
					})}

					{!activeSections?.length ? null : (
						<div className="Tab__section mobile_padding">
							{loading ? (
								<span className="loader primary"></span>
							) : (
								!locked && (
									<div className="button primary" onClick={onSubmit}>
										{isLastTab ? submitMyApplicationText : saveAndContinueText}
									</div>
								)
							)}
						</div>
					)}
				</>
			)}
			<Modal
				isOpen={isServiceAgreementModalOpen}
				extraClassName="service_agreement"
				setVisible={IsCSR && _handleCloseModalAndSubmitTab}
				showHideButton={IsCSR}
			>
				<Iframe src={serviceAgreementUrl} />
			</Modal>
			<Prompt when={isDirty} message={_location => JSON.stringify(_location)} />
		</div>
	);
};

const QuestionWrapper = ({ children, questionTitle, questionId, rowNumber }) => {
	return (
		<div className="question__wrapper" q-id={questionId} style={{ gridRowStart: `${rowNumber}` }}>
			{questionTitle && <div className="question__title" dangerouslySetInnerHTML={{ __html: questionTitle }}></div>}
			{children}
		</div>
	);
};

export default Tab;

const Skeleton = () => {
	return (
		<div className="Skeleton">
			<span className="tab_body"></span>
		</div>
	);
};
