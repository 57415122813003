import { useState, memo } from 'react';
import classNames from 'classnames';

import './style.css';

const InfoBox = memo(({ message, questionId }) => {
	const [isOpen, setIsOpen] = useState(false);

	const Open = () => setIsOpen(true);
	const Close = () => setIsOpen(false);

	const classes = classNames('InfoBox__dropshadow', { open: isOpen, close: !isOpen });

	return (
		<>
			<span onClick={Open} className="InfoBox"></span>
			<div className={classes}>
				<div className="info">
					<div dangerouslySetInnerHTML={{ __html: message }}></div>
					<div className="info__close" onClick={Close}>
						X
					</div>
				</div>
			</div>
		</>
	);
});

export default InfoBox;
