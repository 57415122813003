import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { styles } from 'styles';

export const MOBILE_SIZE = process.env.REACT_APP_MOBILE_SIZE;

export function useScreenSize() {
	const isClient = typeof window === 'object';

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
		};
	}

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return false;
		}

		function handleResize() {
			setWindowSize(getSize());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
		// eslint-disable-next-line
	}, []); // Empty array ensures that effect is only run on mount and unmount

	return windowSize;
}

export const IS_MOBILE = window.innerWidth <= MOBILE_SIZE;

export default class MyPromise {
	constructor() {
		this.promise = new Promise((resolve, reject) => {
			this.reject = reject;
			this.resolve = resolve;
		});
	}
}

export function useTraceUpdate(props) {
	const prev = useRef(props);
	useEffect(() => {
		const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
			if (prev.current[k] !== v) {
				ps[k] = [prev.current[k], v];
			}
			return ps;
		}, {});
		if (Object.keys(changedProps).length > 0) {
			console.log('Changed props:', changedProps);
		}
		prev.current = props;
	});
}

const formatString = process.env.REACT_APP_FORMAT_STRING;

export function stringToDate(dateString) {
	try {
		const [d1, d2, d3] = dateString.split('-').map(item => (parseInt(item) < 10 ? `0${parseInt(item)}` : item)); // adding leading zeros to date

		const date = `${d1}-${d2}-${d3}`; // putting the date back as it was with leading zeroes

		return moment(date, formatString);
	} catch (error) {
		return null;
	}
}

export function deepClone(obj) {
	if (obj === null || typeof obj !== 'object') {
		// If the passed in value is not an object, return the value itself
		return obj;
	}
	// Use the spread operator to create a new array or object
	// that has the same properties and values as the original
	const clone = Array.isArray(obj) ? [...obj] : { ...obj };
	// Recursively clone any nested objects or arrays
	for (const key in clone) {
		clone[key] = deepClone(clone[key]);
	}
	return clone;
}

export function toSentenceCase(str) {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function findDuplicateByPropName(arrayOfObjects, propName) {
	let propCounts = {};
	let duplicateProps = [];

	arrayOfObjects.forEach(obj => {
		propCounts[obj[propName]] = (propCounts[obj[propName]] || 0) + 1;
		if (propCounts[obj[propName]] === 2) {
			duplicateProps.push(obj[propName]);
		}
	});

	return duplicateProps;
}

export function findLast(arrayToFind, cb) {
	if (!arrayToFind || typeof arrayToFind !== 'object') return;
	return arrayToFind.filter(cb).pop();
}

export function isNumber(value) {
	if (typeof value === 'string') {
		return !isNaN(value);
	}
}

//inject the match css from styles/index.js - by siteId
export const injectCSSVariables = siteId => {
	const style = styles.find(style => style.id === siteId);

	let styleElement = document.getElementById('css-variables');
	if (!styleElement) {
		styleElement = document.createElement('style');
		styleElement.id = 'css-variables';
		styleElement.type = 'text/css';
		document.head.appendChild(styleElement);
	}

	styleElement.innerHTML = style ? `:root { ${style.variables} }` : '';
};

// Load only pixel scripts with valid IDs
export const loadPixels = () => {
	const pixelScripts = {
		metaPixel: id => ({
			content: `
      !function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
              n.callMethod ?
                  n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${id}');
      fbq('track', 'PageView');
    `,
		}),

		gtagPixel: id => ({
			src: `https://www.googletagmanager.com/gtag/js?id=${id}`,
			content: `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag("js", new Date());
      gtag("config", "${id}");
    `,
		}),

		analyticsPixel: id => ({
			src: `https://www.googletagmanager.com/gtag/js?id=${id}`,
			content: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
          dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', '${id}');
    `,
		}),

		outbrainPixel: id => ({
			content: `
           !function (_window, _document) {
            var OB_ADV_ID = '${id}';
            if (_window.obApi) { var toArray = function (object) { return Object.prototype.toString.call(object) === '[object Array]' ? object : [object]; }; _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID)); return; }
            var api = _window.obApi = function () { api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments); }; api.version = '1.1'; api.loaded = true; api.marketerId = OB_ADV_ID; api.queue = []; var tag = _document.createElement('script'); tag.async = true; tag.src = '//amplify.outbrain.com/cp/obtp.js'; tag.type = 'text/javascript'; var script = _document.getElementsByTagName('script')[0]; script.parentNode.insertBefore(tag, script);
        }(window, document);
        obApi('track', 'PAGE_VIEW');
    `,
		}),

		quoraPixel: id => ({
			content: `
		!function (q, e, v, n, t, s) { if (q.qp) return; n = q.qp = function () { n.qp ? n.qp.apply(n, arguments) : n.queue.push(arguments); }; n.queue = []; t = document.createElement(e); t.async = !0; t.src = v; s = document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s); }(window, 'script', 'https://a.quora.com/qevents.js');
        qp('init', '${id}');
        qp('track', 'ViewContent');`,
		}),

		taboolaPixel: id => ({
			content: `
        window._tfa = window._tfa || [];
        _tfa.push({notify: 'event', name: 'page_view', id: '${id}'});
        !function (t, f, a, x) {
          if (!document.getElementById(x)) {
            t.async = 1;
            t.src = a;
            t.id = x;
            f.parentNode.insertBefore(t, f);
          }
        }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          'https://cdn.taboola.com/libtrc/unip/${id}/tfa.js',
          'tb_tfa_script');
    `,
		}),

		tagManagerPixel: id => ({
			content: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
    `,
		}),
	};
	const availablePixels = window.Settings?.PIXEL || {};

	Object.entries(availablePixels).forEach(([key, id]) => {
		if (id && pixelScripts[key]) {
			const { content, src } = pixelScripts[key](id);
			// Append the script to the document head
			if (src) {
				const contentScriptElement = document.createElement('script');
				contentScriptElement.async = true;
				contentScriptElement.src = src;
				document.head.appendChild(contentScriptElement);
			}
			if (content) {
				const scriptElement = document.createElement('script');
				if (key === 'outbrainPixel') {
					scriptElement.setAttribute('data-obct', '');
				} else {
					scriptElement.async = true;
				}
				scriptElement.type = 'text/javascript';
				scriptElement.textContent = content.trim();
				document.head.appendChild(scriptElement);
			}
		}
	});
};
