import { SIDETABS_ACTIONS } from './actionTypes';

const sideTabsReducer = (sideTabs, action) => {
	switch (action.type) {
		case SIDETABS_ACTIONS.SET_SIDETABS:
			return { ...action.payload };
		case SIDETABS_ACTIONS.COMPLETE_SIDETAB:
			const TabLayoutList = sideTabs.TabLayoutList.map(sidetab => {
				if (sidetab.TabId === action.payload) return { ...sidetab, IsComplete: true };
				return sidetab;
			});
			return { ...sideTabs, TabLayoutList };
		case SIDETABS_ACTIONS.SET_MENU:
			return { ...sideTabs, IsMenuOpen: action.payload };
		default:
			return sideTabs;
	}
};

export default sideTabsReducer;
