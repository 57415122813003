export const CURRENT_TAB_ACTIONS = { SET_CURRENT_TAB: 'SET_CURRENT_TAB', LOG_OUT: 'LOG_OUT' };

export const LANGAUGE_ACTIONS = { CHANGE_LANG: 'CHANGE_LANG' };

export const SIDETABS_ACTIONS = { SET_SIDETABS: 'SET_SIDETABS', COMPLETE_SIDETAB: 'COMPLETE_SIDETAB', SET_MENU: 'SET_MENU' };

export const TABS_ACTIONS = {
	SET_TABS: 'SET_TABS',
	SET_TAB: 'SET_TAB',
	INSERT_TAB_TO_BEGIN: 'INSERT_TAB_TO_BEGIN',
	INSERT_TAB_TO_END: 'INSERT_TAB_TO_END',
};
