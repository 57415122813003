import { useMemo } from 'react';
import { useAppContext } from 'services/context';
import InfoBox from 'components/InfoBox';
import { Languages } from 'constants/index.js';
import './style.css';

const Input = ({ SetClientAnswer, question, questionType, isValid, showValidationError }) => {
	const { lang, IsLocked } = useAppContext();

	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const onType = e => SetClientAnswer(question.QuestionId, e.target.value);

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		const englishDescription = question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
		return englishDescription || {};
	}, [question, lang]);

	return (
		<div className="Input">
			{description.QuestionText && (
				<div className="title">
					<span dangerouslySetInnerHTML={{ __html: description?.QuestionText || '' }}></span>
					{description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}

					{description.QuestionInformation.length !== 0 && (
						<InfoBox message={description.QuestionInformation} questionId={question.QuestionId} inside />
					)}
				</div>
			)}
			{/* creates unnecessary space between the input and the title  */}
			{/* {description.QuestionText === '' && <div className="title">&nbsp;</div>} */}
			<div className="Input__wrapper">
				<input
					type={questionType.htmlType}
					placeholder={description.PlaceHolder}
					onChange={onType}
					disabled={locked}
					required={question.IsRequired}
					value={question.ClientAnswer.InputValue ? question.ClientAnswer.InputValue : ''}
				></input>
			</div>
			{showValidationError && !isValid(question, questionType) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') || '' }}
				></label>
			)}
		</div>
	);
};

export default Input;
