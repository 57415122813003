import { useEffect, useState } from 'react';
import classNames from 'classnames';
import './style.css';

function Spinner({ visible }) {
	if (visible)
		return (
			<div className="Spinner">
				<div className="dropshadow">
					<div className="spinning"></div>
				</div>
			</div>
		);
	return null;
}

export function ChangesSpinner({ visible, failed, success, extraMessage }) {
	const [message, setMessage] = useState('');
	const classes = classNames('spinning', { failed, success });
	useEffect(() => {
		if (extraMessage) {
			setMessage(extraMessage);
			return;
		}
		if (failed) {
			setMessage('Error has been occurred');
			return;
		}
		setMessage('Done successfully');
	}, [failed, success, extraMessage]);

	if (visible)
		return (
			<div className="ChangesSpinner">
				<div className={classes}></div>
				<div className={`message`}>{message}</div>
			</div>
		);
	return null;
}

export default Spinner;
