import React from 'react';
import { ERROR_MESSAGE, Languages, LOGIN_PAGE } from 'constants/index.js';

import './style.css';

const ButtonText = {
	en: `Relogin now`,
	ru: `Перезайдите в систему сейчас`,
	fr: `Se Reconnecter Maintenant`,
	ar: `إعادة تسجيل الدخول`,
	es: `Re-ingrese ahora`,
	tr: `Tekrar Giriş Yapın`,
};

const ErrorPage = ({ lang, header, siteCssClass }) => {
	const lang_obj = lang ? Object.values(Languages).find(l => l.Id === lang) : Languages.English;

	return (
		<div className={`ErrorPage ${siteCssClass}`}>
			{header && (
				<div className="ErrorPage__header">
					<div className="container ErrorPage__logo"></div>
				</div>
			)}
			<div className="container ErrorPage__main">
				<div className="ErrorPage__text">
					{lang_obj && ERROR_MESSAGE[lang_obj.ISO.toLowerCase()] ? ERROR_MESSAGE[lang_obj.ISO.toLowerCase()] : ''}
				</div>
				<a
					href={lang_obj && LOGIN_PAGE[lang_obj.ISO.toLowerCase()] ? LOGIN_PAGE[lang_obj.ISO.toLowerCase()] : LOGIN_PAGE.en}
					className="ErrorPage__redirect button primary"
				>
					{ButtonText[lang_obj.ISO.toLowerCase()] ? ButtonText[lang_obj.ISO.toLowerCase()] : ButtonText.en}
				</a>
			</div>
		</div>
	);
};

export default ErrorPage;
