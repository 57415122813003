import { useMemo } from 'react';
import { useAppContext } from 'services/context';
import { Link, withRouter } from 'react-router-dom';
import { Languages } from 'constants';

import './style.css';

const WelcomeTab = ({ history, onClickButton }) => {
	const { currentTab, lang } = useAppContext();

	const headerText = useMemo(() => {
		const header = currentTab.Header?.find(header => header.LanguageId === lang);
		if (header) return header.Value;
		const englishHeader = currentTab.Header?.find(header => header.LanguageId === Languages.English.Id);
		return englishHeader?.Value || '';
	}, [currentTab, lang]);

	const contentText = useMemo(() => {
		const content = currentTab.Content?.find(content => content.LanguageId === lang);
		if (content) return content.Value;
		const englishContent = currentTab.Content?.find(content => content.LanguageId === Languages.English.Id);
		return englishContent?.Value || '';
	}, [currentTab, lang]);

	const footerText = useMemo(() => {
		const footer = currentTab.Footer?.find(footer => footer.LanguageId === lang);
		if (footer) return footer.Value;
		const englishFooter = currentTab.Footer?.find(footer => footer.LanguageId === Languages.English.Id);
		return englishFooter?.Value || '';
	}, [currentTab, lang]);

	const instructionYoutubeLink = useMemo(() => {
		const link = currentTab.InstructionYoutubeLink?.find(link => link.LanguageId === lang);
		if (link) return link.Value;
		const englishLink = currentTab.InstructionYoutubeLink?.find(link => link.LanguageId === Languages.English.Id);
		return englishLink?.Value || '';
	}, [currentTab, lang]);

	const incompleteTabList = useMemo(() => {
		return currentTab?.IncompleteTabList?.filter(tab => tab.IsVisible) || [];
	}, [currentTab]);

	const tabsLink = useMemo(() => {
		return incompleteTabList?.filter(tab => tab.MissingQuestionNote || !tab.IsComplete) || [];
	}, [incompleteTabList]);

	const welcomeButtonText = useMemo(() => {
		const button = currentTab?.ButtonText?.find(ButtonText => ButtonText.LanguageId === lang);
		if (button) return button.Value;
		const englishButton = currentTab?.ButtonText?.find(ButtonText => ButtonText.LanguageId === Languages.English.Id);
		if (englishButton) return englishButton.Value;
	}, [currentTab, lang]);

	const _renderTabLink = tab => {
		const description = tab.TabDescriptions.find(desc => desc.Language.Id === lang);
		const missingQuestioNotes = tab?.MissingQuestionNote?.map(item => item.find(text => text.Id === lang)) || [];

		// Description->Iso because tal to lazy to name it correctly (Section title)
		// Description->Name because tal to lazy to name it correctly (Question name)
		return (
			<div key={tab.TabId}>
				<Link
					className="WelcomeTab__link"
					to={{
						pathname: `/tab/${tab.TabId}`,
						search: history?.location?.search || '',
					}}
				>
					{description?.DisplayName || ''}
				</Link>
				<ul>
					{missingQuestioNotes.map((item, idx) => (
						<li key={item.Name}>{`${item.Name} ${item.Iso === description.DisplayName ? '' : item.Iso}`}</li>
					))}
				</ul>
			</div>
		);
	};

	return (
		<div className="WelcomeTab">
			{headerText && <div className="WelcomeTab__header" dangerouslySetInnerHTML={{ __html: headerText }}></div>}
			{contentText && <div className="WelcomeTab__content" dangerouslySetInnerHTML={{ __html: contentText }}></div>}
			<div className="WelcomeTab__links">{tabsLink.map(_renderTabLink)}</div>

			{footerText && <div className="WelcomeTab__content" dangerouslySetInnerHTML={{ __html: footerText }}></div>}
			{welcomeButtonText && (
				<div className="button primary" onClick={onClickButton}>
					{welcomeButtonText || ''}
				</div>
			)}
			{instructionYoutubeLink && <div className="WelcomeTab__content" dangerouslySetInnerHTML={{ __html: instructionYoutubeLink }}></div>}
		</div>
	);
};
export default withRouter(WelcomeTab);
