import React from 'react';
import { useAppContext } from 'services/context';
import { useMemo } from 'react';
import './style.css';
import { Languages } from 'constants/index.js';

export const Progressbar = ({ progress }) => {
	const { lang } = useAppContext();

	const userProgress = useMemo(() => {
		const progressValue = Math.min(Math.max(progress, 0), 100);
		return parseInt(progressValue);
	}, [progress]);

	const progressText = useMemo(() => {
		switch (lang) {
			case Languages.English.Id:
				return `${userProgress}% Application completed`;
			case Languages.French.Id:
				return `${userProgress}% Application terminé`;
			default:
				return `${userProgress}%`;
		}
	}, [lang, userProgress]);

	return (
		<div className="Progressbar">
			<div className="userprogress" data-after={progressText} style={{ width: `${userProgress}%` }}></div>
		</div>
	);
};
