import React, { useState, useEffect, useMemo } from 'react';
import { useAppContext } from 'services/context';
import InfoBox from 'components/InfoBox';
import { Languages } from 'constants/index.js';

import './style.css';

// render each month in the month grid with validation
const Month = ({ month, index, questionTitle, fromDate, year, changeMonth }) => {
	const monthClass =
		(questionTitle.includes('to') && fromDate.year === year && index + 1 < fromDate.month) ||
		(year === new Date().getFullYear() && index + 1 > new Date().getMonth() + 1)
			? 'pointer-events-none'
			: '';

	return (
		<div key={month} className={`month ${monthClass}`} onClick={() => changeMonth(index)}>
			{month}
		</div>
	);
};

const MonthPciker = ({
	question,
	showValidationError,
	isValid,
	SetClientAnswer,
	questionType,
	fromDate,
	setFromDate,
	saveFromDateInGlobalStateVariable,
}) => {
	const { lang, IsLocked } = useAppContext();

	const [isOpen, setOpen] = useState(false);
	const [year, setYear] = useState(parseInt(question.ClientAnswer?.InputValue?.split('/')[1]) || new Date().getFullYear());
	const [month, setMonth] = useState(parseInt(question.ClientAnswer?.InputValue?.split('/')[0]) || new Date().getMonth() + 1);
	const [classLeftArrowValidation, setClassLeftArrowValidation] = useState('');
	const [classRightArrowValidation, setClassRightArrowValidation] = useState('');
	const [currFromDate, setCurrFromDate] = useState({});

	const questionTitle = question.QuestionDescriptions[0].QuestionText.toLowerCase();

	const locked = useMemo(() => {
		return IsLocked || !question.IsEditable;
	}, [IsLocked, question]);

	const decrement = () => {
		if (questionTitle.includes('from')) {
			const fromQuestionData = { questionId: question.QuestionId, year: year - 1, month };
			const _fromDate = saveFromDateInGlobalStateVariable(fromQuestionData);
			setFromDate([..._fromDate]);
		}
		setYear(year - 1);
	};
	const increment = () => {
		let fromQuestionData = {};
		if (questionTitle.includes('from')) {
			fromQuestionData = { questionId: question.QuestionId, year: year + 1, month };
		}
		if (year + 1 === new Date().getFullYear() && currFromDate.month > new Date().getMonth() + 1) {
			fromQuestionData = { questionId: question.QuestionId, year, month: new Date().getMonth() + 1 };

			if (questionTitle.includes('from')) setMonth(new Date().getMonth() + 1);
		}
		if (fromQuestionData) {
			const _fromDate = saveFromDateInGlobalStateVariable(fromQuestionData);
			setFromDate([..._fromDate]);
		}
		setYear(year + 1);
	};

	const changeMonth = index => {
		setMonth(index + 1);
		setOpen(false);
		if (questionTitle.includes('from')) {
			const fromQuestionData = { questionId: question.QuestionId, year, month: index + 1 };
			const _fromDate = saveFromDateInGlobalStateVariable(fromQuestionData);
			setFromDate([..._fromDate]);
		}
	};

	const Toggle = () => {
		if (!locked) setOpen(!isOpen);
	};

	useEffect(() => {
		const date = {
			month: question.ClientAnswer.InputValue ? parseInt(question.ClientAnswer.InputValue.split('/')[0]) : null,
			year: question.ClientAnswer.InputValue ? parseInt(question.ClientAnswer.InputValue.split('/')[1]) : null,
		};

		if (date.month !== month || date.year !== year) SetClientAnswer(question.QuestionId, `${month}/${year}`);

		if (questionTitle.includes('from')) {
			let _fromDate = [...fromDate];
			const currQuestion = _fromDate?.find(currQuestion => currQuestion.questionId === question.QuestionId);
			if (!currQuestion) {
				_fromDate.push({ questionId: question.QuestionId, year: date.year, month: date.month });
				setFromDate([..._fromDate]);
			}
		}
	}, [year, month]);

	useEffect(() => {
		let _currFromDate;
		if (questionTitle.includes('from'))
			_currFromDate = fromDate?.filter(currQuestion => currQuestion.questionId === question.QuestionId)[0] || {};
		else _currFromDate = fromDate?.filter(currQuestion => currQuestion.questionId === question.QuestionId - 2)[0] || {};
		setCurrFromDate(_currFromDate);

		if (questionTitle.includes('to') && year - 1 < currFromDate.year) {
			setClassLeftArrowValidation('hidden-element');
			setYear(new Date().getFullYear());
			setMonth(new Date().getMonth() + 1);
		} else setClassLeftArrowValidation('');
	}, [fromDate]);

	// Year validation
	useEffect(() => {
		if (questionTitle.includes('to') && year - 1 < currFromDate.year) setClassLeftArrowValidation('hidden-element');
		else setClassLeftArrowValidation('');

		if (year + 1 > new Date().getFullYear()) setClassRightArrowValidation('hidden-element');
		else setClassRightArrowValidation('');
	}, [fromDate, year]);

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	const description = useMemo(() => {
		const text = question.QuestionDescriptions.find(description => description.Language.Id === lang);
		if (text) return text;
		return question.QuestionDescriptions.find(description => description.Language.Id === Languages.English.Id);
	}, [question, lang]);

	return (
		<div className="MonthPicker">
			{description.QuestionText && (
				<div className="MonthPicker__title">
					<span dangerouslySetInnerHTML={{ __html: description?.QuestionText || '' }}></span>
					{description.QuestionText !== '' && question.IsRequired && <span className="required">*</span>}
					{description.QuestionInformation.length !== 0 && (
						<InfoBox message={description.QuestionInformation} QuestionId={question.QuestionId} />
					)}
					&nbsp;
				</div>
			)}
			<div className={`MonthPicker__input ${locked ? 'disabled' : ''}`}>
				<div className="date" onClick={Toggle}>
					<div className={`date-icon`}></div>
					<span>
						{months[month - 1]} {year}
					</span>
				</div>

				<div className={`dates-container ${isOpen ? 'dates-container--open' : ''}`}>
					<div className={`left-arrow ${classLeftArrowValidation}`} onClick={decrement}></div>
					<div className="year">{year}</div>
					<div className={`right-arrow ${classRightArrowValidation}`} onClick={increment}></div>
					<div className="months-grid">
						{months.map((month, index) => (
							<Month
								month={month}
								index={index}
								questionTitle={questionTitle}
								fromDate={currFromDate}
								year={year}
								changeMonth={changeMonth}
							/>
						))}
					</div>
				</div>
			</div>
			{showValidationError && !isValid(question, questionType) && (
				<label
					className="validation_error"
					dangerouslySetInnerHTML={{ __html: description?.QuestionNoteTextArray?.join('<br/>') || '' }}
				></label>
			)}
		</div>
	);
};

export default MonthPciker;
